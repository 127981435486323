.facebook-21-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 810px;
    height: 535.313px;
  }

  .image2 {
    position: absolute;
    top: 0;
    right: 0;
    width: 810px;
    height: 535.313px;
  }

  .image3 {
    position: absolute;
    top: 544.687px;
    left: 0;
    width: 810px;
    height: 535.313px;
  }

  .image4 {
    position: absolute;
    top: 544.687px;
    right: 0;
    width: 810px;
    height: 535.313px;
  }

  .content-wrapper {
    text-align: center;
    position: absolute;
    top: 290px;
    left: 474.25px;
    width: 683.5px;
    height: 500px;
    background-color: white;
    opacity: 0.8;
  }

  .headline {
    padding-top: 50px;
    margin-top: 20px;
    font-size: 100px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 2px;
    background: transparent;
  }

  hr {
    width: 400px;
    height: 3px;
    background-color: #83bd41;
  }

  .logo {
    margin-top: 60px;
  }

  &.black {
    .headline {
      color: black;
    }
    hr {
      color: black;
      background: black;
    }
  }

  &.green {
    .headline {
      color: #112f23;
    }
    hr {
      color: #83bd41;
      background: #83bd41;
    }
  }
}
