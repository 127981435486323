.universalPemberton2 .logo img {
  height: 100%;
  object-fit: contain;
  object-position: left;
}
.half-listing-feature {
  flex-wrap: wrap;
  width: 540px;
  align-items: flex-start;

  &--header {
    display: flex;
    justify-content: space-between;
    height: 65px;
    width: 100%;
    padding: 10px 10px;
  }

  .flex {
    display: flex;
  }

  .empty-placeholder {
    background-image: url('../../../../images/camera.svg');
    background-size: 64px;
  }

  .features {
    width: 266px;
    padding: 14px 30px;
    font-family: 'OvoR', Verdana, Tahoma;
    position: relative;

    .title {
      font-weight: bold;
      margin-bottom: 10px;
      color: var(--font-color);
    }
    .feature-list {
      columns: 1;
      line-height: 22px;
    }

    .feature-list,
    .feature-inc {
      font-size: 10px;
    }

    .feature-list span {
      width: 50%;
    }

    .feature-list span.feature-name {
      width: 100px;
      display: inline-block;
    }

    .feature-inc {
      margin-top: 4px;
      line-height: 1.2em;

      span.feature-name {
        width: 100px;
        display: inline-block;
      }
    }

    .feature-inc {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .feature-inc > div {
      margin-bottom: 9px;
    }

    &:hover .edit-btn-hover {
      opacity: 1;
    }

    &:hover > div {
      opacity: 0.5;
    }
  }
}

.black {
  .half-listing-feature {
    &--header {
      background-color: black;
    }
  }
}

.green {
  .half-listing-feature {
    &--header {
      background-color: #112f23;
    }
  }
}
