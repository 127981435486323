.whistler-facebook-2-wrapper {
  width: 1200px;
  height: 628px;

  .image1 {
    position: absolute;
    width: inherit;
    height: inherit;
  }

  .content-wrapper {
    position: absolute;
    width: 300px;
    height: 100%;
    padding: 60px 50px;
    text-align: left;
    font-family: 'AcuminR';
    background: rgba(255, 255, 255, 0.9);
  }

  .headline {
    font-size: 33px;
  }

  hr {
    width: 50px;
    height: 2px;
    background: var(--whistler-red);
    margin: 40px 0;
  }

  .address,
  .listingDetails {
    font-family: 'AcuminR';
    margin-bottom: 30px;
    font-size: 16px;
    color: var(--whistler-grey);
  }

  .logo {
    position: relative;
    top: 210px;
    width: 150px;
    margin: auto;
  }
}
