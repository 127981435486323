.mac-template-4 {
  &.page-1,
  &.page-2 {
    width: 610px;
    height: 792px;
    padding: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .mac-listing-info {
    display: block;

    .listing-desc-container {
      width: 494px;
      margin: auto;
      height: 152px;

      .address {
        color: var(--mac-font-color-grey);
      }

      .description {
        line-height: 14px;
      }
    }

    .photo-layout-1.large {
      margin-bottom: 10px;
      height: 238px;

      .image-container .listing-photo {
        // height: 238px !important;
      }

      .stacked .listing-photo {
        // height: 114px !important;
      }
    }

    .photo-layout-2 {
      margin-bottom: 20px;

      .image-container .listing-photo {
        height: 116px !important;
      }
    }

    .brochure-header {
      h1,
      h2 {
        color: var(--mac-font-color-grey);
      }
    }
  }
}
