.mac-template-6 {
  width: 612px;
  height: 792px;
  padding: 36px;
  position: relative;
  overflow: hidden;

  .mac-window-poster > .image-container {
    .listing-photo {
      width: 540px !important;
      height: 366px !important;
    }
  }

  .mac-window-poster {
    .legal {
      margin-top: 15px;
    }
    .stacked {
      height: 210px;

      .image-container .listing-photo {
        width: 160px !important;
        height: 100px !important;
      }
    }
    .two-agents {
      margin-left: 23px;
      margin-bottom: 10px;

      .agent-branding {
        margin: 5px 0 0 0;
        padding: 0 20px 0 0;

        .agent-info {
          // width: 62% !important;
        }
      }

      .agent-branding:first-of-type {
        margin-right: 0px;
      }
    }
  }

  .listing-desc {
    padding: 0 0 0 24px;

    .address {
      margin-top: 20px;
    }
  }
}
