.vw-social-3-wrapper {
  .image1 {
    position: absolute;
    width: inherit;
    height: inherit;
    background: center no-repeat url('../../../images/camera.svg');
    background-size: 64px;
  }
  .overlay {
    position: absolute;
    background-color: #1c77a0a8;
    top: 0;
    left: 0;
    width: 300px;
    z-index: 999;
    height: 100%;
  }
  .card-div {
    background: white;
    position: absolute;
    z-index: 9999;
    height: 75%;
    top: 50%;
    transform: translateY(-50%);
    left: 10%;
    padding: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    justify-items: flex-start;
    width: 280px;
    align-items: center;

    .headline {
      font-size: 35px;
      position: relative;
      color: #1c77a0;
      font-family: 'MontserratM';
      font-weight: 500;
      margin-bottom: 10px;
      margin-top: 15px;

      textarea {
        font-weight: 500;
        color: #515153;
      }
    }

    .hr {
      border-top: 2px solid #1c77a0;
      display: block;
      width: 100%;
      margin-bottom: 50px;
    }

    .address,
    .listingDetails {
      color: #b3a4a2;
      font-size: 15px;
      font-family: 'MontserratR';
      margin: 0 0 15px;
    }

    .brand-logo {
      width: 100%;
      height: 80px;
      margin-top: auto;
      object-fit: contain;
      margin-bottom: 20px;
    }
  }
}
