.realvault-swal .swal2-confirm.swal2-styled {
  background-color: #98d216 !important;
  border-color: #98d216 !important;
  color: #fff;
}

.swal2-styled.swal2-cancel {
  background-color: #233349 !important;
  border-color: #233349 !important;
}

.realvault-swal {
  font-family: sans-serif;
}
