.universal-window-poster {
  .listing-info {
    &--header {
      display: flex;
      justify-content: space-between;
      height: 65px;
      width: 100%;
      padding: 0 10px;
    }
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 540px;
    align-items: flex-start;

    .empty-placeholder {
      background-image: url('../../../../images/camera.svg');
      background-size: 64px;
    }

    .logo {
      position: relative;
    }
    .listing-intro {
      font-size: 15px;
      font-family: 'OvoR', Verdana, Tahoma;
      text-align: right;
      color: white;

      h1,
      h2 {
        font-size: 20px;
        line-height: 1.2em;
        max-width: 420px;
        max-height: 35px;
        overflow: hidden;
      }
    }

    .listing-intro div {
      line-height: 10px;
    }
  }

  .info-photo-layout {
    display: flex;
    height: 215px;
    width: 540px;
    justify-content: space-between;
    margin-top: 10px;
  }

  .info-photo-layout .stacked {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .listing-desc {
    line-height: 24px;
    width: 350px;
    position: relative;
    padding-left: 20px;
    padding-right: 20px;
    font-family: 'OvoR', Verdana, Tahoma;

    .address {
      font-size: 17px;
      color: var(--font-color);
      margin-bottom: 10px;
      margin-top: 15px;
      line-height: 1em;
    }

    .description {
      font-size: 9.5px;
      line-height: 13px;

      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .details {
      margin-top: 10px;
      font-size: 10px;
      color: #3d464d;
      display: flex;
      .item {
        margin-right: 30px;
      }
    }

    &:hover .edit-btn-hover {
      opacity: 1;
    }

    &:hover > div,
    &:hover > span {
      opacity: 0.5;
    }
  }

  .two-agents {
    display: flex;

    .agent-branding.no-logo:first-of-type {
      margin-right: 10px;
      min-width: 49%;
    }

    .agent-contact {
      font-size: 9px;
    }
    .agent-branding.no-logo .agent-photo {
      min-width: 80px;
      max-width: 80px;
    }
  }

  .agent-branding.no-logo {
    margin-top: 10px;
    padding-left: 20px;

    .agent-photo {
      min-width: 85px;
      max-width: 85px;
      margin-top: 0;
      height: 110px;
    }

    .agent-info {
      width: 100%;
      justify-content: center;
      padding-top: 5px;
      .agent-name {
        border-top: grey 1px solid;
        padding-top: 8px;
      }
      .agent-name,
      .agent-phone {
        font-size: 13.6px;
      }
      .agent-title,
      .agent-contact {
        font-size: 8.5px;
      }
    }

    .agent-phone {
      font-size: 16px;
    }
  }

  .legal {
    position: relative;
    left: 123px;
    margin-top: 7px;
    font-size: 6px;
    font-family: 'OvoR', Verdana, Tahoma;
  }
}
.template-6 {
  .legal {
    left: 118px;
  }
}

.black {
  .window-poster .listing-info {
    &--header {
      background-color: black;
    }
  }
}

.green {
  .window-poster .listing-info {
    &--header {
      background-color: #112f23;
    }
  }
}
