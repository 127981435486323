.whistler-social-4-wrapper {
  .image1 {
    position: absolute;
    width: inherit;
    height: inherit;
  }

  .content-wrapper {
    position: absolute;
    width: 450px;
    height: 100%;
    padding: 80px 50px;
    text-align: left;
    font-family: 'AcuminR';
  }

  .headline {
    font-size: 45px;
    color: var(--whistler-red);
  }

  hr {
    width: 100px;
    height: 3px;
    background-color: var(--whistler-red);
    margin: 40px 0;
  }

  .address,
  .listingDetails {
    margin-bottom: 30px;
    font-size: 28px;
  }

  .logo {
    position: absolute;
    top: 820px;
    width: 250px;
    margin: auto;
    left: 25%;
  }
}
