.mac-floorplan {
  width: 540px;
  position: relative;

  .image-container .listing-photo {
    background-image: url('../../../../images/floorplan.svg');
    background-position: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  .scale-back.upload-button {
    position: absolute;
    top: 49%;
    left: 33%;
    transform: translate(-49%, -33%);
    -ms-transform: translate(-49%, -33%);
  }

  .floorplan-img:hover {
    .upload-floorplan-pdf,
    .image-container .upload-button {
      opacity: 1;
    }

    .listing-photo {
      opacity: 0.5;
    }
    .listing-photo .empty-placeholder {
      outline: 1px solid #219bd0;
    }
  }

  .upload-floorplan-pdf {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    opacity: 0;
  }

  .empty-placeholder {
    background-image: url('../../../../images/floorplan.svg');
    background-size: 128px;
  }

  .info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 27px 20px 0;

    .agent-info {
      position: relative;
      .intro {
        margin-bottom: 8px;
        font-size: 11px;
        font-family: 'UbuntuM', Verdana, Tahoma;
        color: var(--mac-font-color);
      }

      .agent-name {
        font-size: 16px;
        font-family: 'UbuntuM', Verdana, Tahoma;
        color: var(--mac-font-color);
        line-height: 20px;
      }

      .agent-phone {
        font-size: 16px;
        font-family: 'UbuntuL', Verdana, Tahoma;
      }

      .agent-title,
      .agent-contact {
        font-size: 10px;
        font-family: 'UbuntuL', Verdana, Tahoma;
        line-height: 14px;
      }

      .agent-contact span {
        font-family: 'UbuntuM', Verdana, Tahoma;
        color: var(--mac-font-color);
        margin-right: 4px;
      }

      &:hover > div,
      &:hover > span {
        opacity: 0.5;
      }

      .edit-btn-hover {
        width: 75%;
      }

      &:hover .edit-btn-hover {
        opacity: 1;
      }
    }

    .logo {
      widows: 115px;
      margin-top: 10px;
    }
  }

  .legal {
    margin: 5px 20px 0;

    &.small-margin {
      margin: 7px 20px 0;
    }

    font-size: 6px;
    font-family: 'UbuntuL', Verdana, Tahoma;
  }

  .listing-desc-container,
  .agent-banner-container {
    position: relative;

    &:hover .edit-btn-hover {
      opacity: 1;
    }

    &:hover > div {
      opacity: 0.5;
    }
  }

  .agent-banner-container {
    width: 540px;
    height: 110px;

    .empty-placeholder {
      background-image: url('../../../../images/banner.svg');
      background-size: 64px;
    }

    &:hover .empty-placeholder {
      outline: 1px solid #219bd0;
    }

    &:hover .empty-placeholder {
      opacity: 0.5;
    }
  }
}
