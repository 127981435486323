.whistler-social-18-wrapper {
  .details-container {
    display: flex;
    justify-content: center;
    background-color: white;
    height: 100%;
    padding: 150px 60px;

    .testimonial {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      width: 60%;
      text-align: center;
      color: var(--whistler-red);

      .headline {
        font-size: 75px;
        font-family: 'AcuminB';
      }

      hr {
        width: 30%;
        height: 3px;
        background-color: var(--whistler-red);
        margin: 0px auto 150px auto;
      }

      .content {
        font-size: 35px;
        font-family: 'AcuminR';
        color: var(--whistler-grey);
        ::placeholder {
          color: var(--whistler-grey);
        }
      }

      .client-name {
        font-size: 20px;
        font-family: 'AcuminR';
        color: var(--whistler-red);
        ::placeholder {
          color: var(--whistler-red);
        }
      }
    }
  }
}
