.facebook-23-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    right: 0;
    width: 1088px;
    height: 880px;
  }

  .image2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 544px;
    height: 440px;
  }

  .image3 {
    position: absolute;
    top: 440px;
    left: 0;
    width: 544px;
    height: 440px;
  }
  .content-wrapper {
    justify-content: space-between;
    padding: 20px 100px;
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 200px;
    align-items: center;
    background-color: #112f23;
  }

  .headline {
    width: 900px;
    font-size: 65px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;
    color: white;
    background: transparent;
  }

  .address {
    width: 746px;
    font-size: 40px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: white;
    background: transparent;
  }

  &.black {
    .content-wrapper {
      background-color: black;
    }
  }

  &.green {
    .content-wrapper {
      background-color: #112f23;
    }
  }
}
