.action-row {
  i {
    margin: 0 10px;
  }
}

.add-button {
  margin-bottom: 15px !important;
}

.tooltip {
  font-family: 'Primary', Verdana, Tahoma;
}

.empty-message {
  margin-top: 100px !important;
  text-align: center;
  font-family: 'Primary', Verdana, Tahoma;
}
