.App {
  font-family: 'MontserratR';
  .app-container {
    .app-row {
      .sidebar {
        .admin-layout {
          background-color: #233349;
          color: white;
          width: 100%;
          height: 100%;
          padding: 24px;

          display: flex;
          flex-direction: column;
          justify-content: space-between;

          .navbar-container {
            margin-bottom: 16px;

            .admin-nav {
              font-size: 16px;
              font-weight: bold;
              padding: 6px;
              display: block;
              color: white;

              &.active-class {
                color: yellowgreen;
              }

              &:focus {
                outline: none;
              }
            }

            .admin-sub-nav {
              font-weight: normal;
              padding: 6px;
              margin: 4px 0 4px 20px;
              display: block;
              color: white;

              &.active-class {
                color: yellowgreen;
              }

              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }
}
