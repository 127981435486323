.mac-facebook-13-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 715.317px;
    height: 531.808px;
  }

  .image2 {
    position: absolute;
    top: 0;
    left: 729.391px;
    width: 350.609px;
    height: 259.215px;
  }

  .image3 {
    position: absolute;
    top: 273.595px;
    left: 729.391px;
    width: 350.609px;
    height: 259.215px;
  }

  .image4 {
    position: absolute;
    top: 547.19px;
    left: 0;
    width: 350.609px;
    height: 259.215px;
  }

  .image5 {
    position: absolute;
    top: 820.785px;
    left: 0;
    width: 350.609px;
    height: 259.215px;
  }

  .headline {
    position: absolute;
    top: 632px;
    left: 424px;
    width: 460px;

    font-size: 65px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;
    color: rgb(3, 44, 143);
    background: transparent;
  }

  hr {
    position: absolute;
    top: 749.893px;
    left: 424px;
    width: 93.775px;
    height: 12px;
    color: rgb(3, 44, 143);
    background: rgb(3, 44, 143);
  }

  .address {
    position: absolute;
    top: 830.858px;
    left: 424px;
    width: 578.215px;

    font-size: 40px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .detail {
    position: absolute;
    top: 960.852px;
    left: 424px;
    width: 611.335px;

    font-size: 30px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .logo {
    position: absolute;
    top: 640px;
    left: 890px;
    width: 170px;
    height: auto;
  }
}
