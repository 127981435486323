.vw-social-template-3-wrapper {
  position: relative !important;
  overflow: hidden;
  height: inherit;
  width: inherit;

  .image1 {
    height: 1080px;
    height: 1080px;
    object-fit: cover;

    background: center no-repeat url('../../../images/camera.svg');
    background-size: 64px;
  }
  .headline {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #165f868e;
    color: white;
    font-family: 'MontserratM';
    font-weight: bold;
    font-size: 85px;
    width: 100%;
    text-align: center;
    transform: rotate(-35deg) translate(-30%, -90px);
    letter-spacing: 2px;

    textarea {
      font-weight: bold;
      color: white;
      text-transform: uppercase;
    }
    textarea:hover {
      color: black;
    }
  }
  .logo-img {
    position: absolute;
    width: auto;
    height: 120px;
    top: 15px;
    right: 15px;
    object-fit: contain;
  }
}
