.template-actions .ui.compact.menu {
  align-items: center;
  background: none;
  border: none;
  box-shadow: none;
  border-radius: 5px;
  margin: 0 0.25rem 0 0;
  vertical-align: baseline;

  .ui.dropdown:hover {
    box-shadow: 0 0 0 1px transparent inset, 0 0 0 0 rgba(34, 36, 38, 0.15) inset;
    color: rgba(0, 0, 0, 0.8);
  }

  .ui.item.dropdown {
    color: white;
    &:hover {
      position: relative;
      z-index: 11;
      background: linear-gradient(to bottom, #6375a3, #6477a8);
    }
    &.disabled {
      color: white !important;
    }
  }

  .ui.dropdown {
    justify-content: space-between;
    display: inline-block;
    vertical-align: baseline;
    color: white;
    background: linear-gradient(to bottom, #4a65aa, #4e6bb4);
    border: none;
    border-radius: 0.28571429rem;
    outline: 0;
    width: 165px;
    min-height: 1em;
    line-height: 1em;
    padding: 0.78571429em 1.5em 0.78571429em;
    font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 1rem;
    text-align: center;
    text-transform: none;
    text-shadow: none;

    .ui.button {
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;

      .icon {
        display: none;
      }
    }
  }
}

.template-actions .ui.menu .ui.dropdown .menu > .item {
  text-align: center;
}

.template-actions .ui.button {
  color: white;
}
