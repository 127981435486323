.mac-facebook-12-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 1080px;
    height: 1080px;
  }

  .content-wrapper {
    position: absolute;
    top: 562px;
    left: 0;
    width: 1080px;
    height: 398px;
    background-color: rgb(225, 220, 208);
    opacity: 0.9;
  }

  .mainhead {
    position: absolute;
    top: 600px;
    left: 80px;
    width: 800px;

    font-size: 60px;
    font-family: 'LuxiaR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;
    color: rgb(3, 44, 143);
    background: transparent;
  }

  hr {
    position: absolute;
    top: 776.641px;
    left: 80px;
    width: 93.775px;
    height: 12px;
    color: rgb(3, 44, 143);
    background: rgb(3, 44, 143);
  }

  .title {
    position: absolute;
    top: 837.564px;
    left: 80px;
    width: 760px;

    font-size: 40px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .detail {
    position: absolute;
    top: 892.415px;
    left: 80px;
    width: 800px;

    font-size: 30px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .logo {
    position: absolute;
    top: 780px;
    left: 820px;
    width: 225.583px;
    height: auto;
  }
}
