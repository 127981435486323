.agent-branding {
  &.alternateStyle {
    justify-content: flex-start !important;
    p,
    input {
      font-family: 'AcuminR' !important;
    }
  }
  display: flex;
  justify-content: space-between;
  position: relative;

  .agent-photo {
    width: 84px;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    margin-right: 18px;
    position: relative;

    .empty-placeholder {
      background-image: url('../images/person.svg');
      background-size: 64px;
    }
  }

  .agent-info {
    width: 243px;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    font-family: 'Primary', Verdana, Tahoma;

    .intro {
      margin-bottom: 10px;
      font-size: 11px;
      color: var(--font-color);
    }

    .agent-name {
      font-size: 16px;
      color: var(--font-color);

      &.alternateStyle {
        input {
          font-family: 'AcuminR' !important;
          font-weight: normal;
          color: black !important;
        }
      }
    }

    .agent-phone {
      font-size: 16px;
    }

    .agent-title,
    .agent-contact {
      font-size: 10px;
      line-height: 14px;
    }

    .agent-contact span {
      color: var(--font-color);
      margin-right: 4px;
    }
  }

  .agent-logo {
    text-align: right;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    width: 190px;

    img {
      height: 47px;
    }
  }

  .agent-title,
  .agent-phone {
    margin: 1px 0;
  }

  .agent-contact span {
    line-height: 1em;
  }

  .edit-btn-hover {
    width: 75%;
    text-align: center;
  }

  &:hover .edit-btn-hover {
    opacity: 1;
  }

  &:hover > div {
    opacity: 0.5;
  }
}

.green {
  .agent-branding .agent-logo {
    width: 190px;

    img {
      height: 47px;
    }
  }
}
.black {
  .agent-branding .agent-logo {
    width: 200px !important;

    img {
      height: 60px;
    }
  }
}

.mac.agent-branding {
  .agent-info {
    .intro {
      font-family: 'UbuntuM', Verdana, Tahoma;
      color: #0054a5;
    }

    .agent-name {
      font-family: 'UbuntuM', Verdana, Tahoma;
      color: #0054a5;
    }

    .agent-phone {
      font-family: 'UbuntuL', Verdana, Tahoma;
    }

    .agent-title,
    .agent-contact {
      font-family: 'UbuntuL', Verdana, Tahoma;
    }

    .agent-contact span {
      font-family: 'UbuntuM', Verdana, Tahoma;
      color: #0054a5;
    }
  }
}

.wrc.agent-branding {
  input,
  p,
  span,
  div {
    font-family: 'AcuminR' !important;
  }

  .agent-logo {
    text-align: right;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 190px;

    img {
      height: 55px;
    }
  }
}
