.whistler-social-14-wrapper {
  .image1 {
    position: relative;
    width: inherit;
    height: 860px;

    .image-content {
      background: var(--whistler-black);
    }

    .background-image {
      opacity: 0.5 !important;
    }
  }

  .content-wrapper {
    position: absolute;
    top: calc(50% - 220px);
    width: 100%;
    height: 100px;
    text-align: center;

    .headline {
      width: 90%;
      margin: auto;
      font-size: 140px;
      font-family: 'AcuminB';
      color: white;
    }
  }

  .details-container {
    height: 220px;
    display: flex;
    align-items: center;
    justify-content: center;

    .logo {
      width: 350px;
        img {
        margin: auto;
      }
    }
  }
  .content-wrapper .editable {
    color: #fff;
  }
  .content-wrapper .editable:hover {
    color: #000;
  }
}
