.search-mls {
  width: 620px;
  margin-bottom: 5px;
}

.search-input {
  width: 100%;
  height: 70px;
  font-size: 1em;
  font-weight: bold;
}

.search-input.ui.input > input {
  border: 2px solid #eaedef;
  font-size: 1.5em;
  font-weight: bold;
  background-color: #eaedef;
}

.search-results-container {
  width: 620px;
}

.search-button.ui.button {
  background: #3d3d3d;
  color: white;
}

.search-error {
  background: #eaedef;
  color: red;
  margin-top: 5px;
  width: 97%;

  padding: 1em 2em;
  padding: 1.5em 2em;
  text-align: left;
}

.search-entering {
  color: #3d3d3d;
  background: #eaedef;

  margin-top: 5px;
  width: 97%;
  padding: 1em 2em;
  padding: 1.5em 2em;
  text-align: left;
}
