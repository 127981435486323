.whistler-brochure-5-wrapper,
.whistler-brochure-6-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.whistler-brochure-5-wrapper,
.whistler-brochure-6-wrapper {
  .main-area {
    height: 730px;
    width: 100%;

    .floorplan {
      height: 730px;
      width: 100%;
      position: relative;

      .floorplan-img {
        position: relative;
        .image-container {
          .scale-back {
            position: absolute;
            top: 44%;
            left: 34%;
            transform: translate(-49%, -33%);
            -ms-transform: translate(-49%, -33%);
          }
        }
        .upload-floorplan-pdf {
          position: absolute;
          top: 57%;
          left: 50%;
          transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
          opacity: 0;

          &.lg {
            top: 63%;
          }
        }
      }

      .floorplan-img:hover {
        .upload-floorplan-pdf,
        .image-container .upload-button {
          opacity: 1 !important;
        }

        .listing-photo {
          opacity: 0.5 !important;
        }
      }
    }
  }
}
