.add-settings-modal {
  .mt {
    margin-top: 16px !important;
  }
  .mb {
    margin-bottom: 4px !important;
  }
  .uploaded-image {
    margin-left: 20px;
  }
  .self-start {
    align-self: flex-start;
  }
}
