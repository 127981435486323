.floorplan {
  width: 540px;
  position: relative;

  .listing-info {
    &--header {
      display: flex;
      justify-content: space-between;
      height: 65px;
      width: 100%;
      padding: 0 10px;
    }
  }
  .logo--white {
    position: relative;
  }

  .image-container .listing-photo {
    background-image: url('../../../../images/floorplan.svg');
    background-position: no-repeat;
    background-position: center center;
    background-size: contain;
  }

  .scale-back.upload-button {
    position: absolute;
    top: 50%;
    left: 33%;
    transform: translate(-49%, -33%);
    -ms-transform: translate(-49%, -33%);
  }

  .floorplan-img:hover {
    .upload-floorplan-pdf,
    .image-container .upload-button {
      opacity: 1;
    }

    .listing-photo {
      opacity: 0.5;
    }
  }

  .upload-floorplan-pdf {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    opacity: 0;

    &.lg {
      top: 63%;
    }
  }

  .empty-placeholder {
    background-image: url('../../../../images/floorplan.svg');
    background-size: 128px;
  }

  .info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: 27px 20px 0;

    .agent-info {
      position: relative;
      font-family: 'OvoR', Verdana, Tahoma;

      .intro {
        margin-bottom: 14px;
        font-size: 11px;
        color: var(--font-color);
      }

      .agent-name {
        font-size: 16px;
        color: var(--font-color);
        line-height: 20px;
      }

      .agent-phone {
        font-size: 16px;
      }

      .agent-title,
      .agent-contact {
        font-size: 10px;
        line-height: 14px;
      }

      .agent-contact span {
        color: var(--font-color);
        margin-right: 4px;
      }

      &:hover > div,
      &:hover > span {
        opacity: 0.5;
      }

      .edit-btn-hover {
        width: 75%;
      }

      &:hover .edit-btn-hover {
        opacity: 1;
      }
    }
  }

  .legal {
    margin: 15px 20px 0;
    font-size: 6px;
    font-family: 'OvoR', Verdana, Tahoma;
  }
}

.black {
  .floorplan {
    .listing-info {
      &--header {
        background-color: black;
      }
      .agent-banner-container {
        &:hover .empty-placeholder {
          outline: 1px solid black;
        }
      }
    }
    .logo {
      height: 60px;
    }
    .floorplan-img:hover {
      .listing-photo .empty-placeholder {
        outline: 1px solid black;
      }
    }
  }
}

.green {
  .floorplan {
    .listing-info {
      &--header {
        background-color: #112f23;
      }
      .agent-banner-container {
        &:hover .empty-placeholder {
          outline: 1px solid #19472cd6;
        }
      }
    }

    .logo {
      height: 60px;
    }

    .floorplan-img:hover {
      .listing-photo .empty-placeholder {
        outline: 1px solid #19472cd6;
      }
    }
  }
}
