.landing-page-background {
  background-image: url('../../images/Project/Login.jpg');

  /* Full height */
  height: 100vh;
  width: 100vw;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.page-center {
  @include center(true, true);
}

.w-100p {
  width: 100%;
}

.background {
  border-radius: 5px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  padding: 30px;
  &.white {
    background-color: rgb(255, 255, 255);
  }
  &.grey {
    background-color: #eaedef;
  }
}

.padding {
  &--sm {
    padding: 10px;
  }
  &--md {
    padding: 20px;
  }
}

.margin {
  &--sm {
    margin: 10px;
  }
  &--md {
    margin: 20px !important;
  }
}

.full-page-logo {
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
  height: 100vh;
  // since it is rotated, width takes on height
  width: 100vh;
  position: fixed;
  top: 20px;
  left: -500px;
}

.background-image--landing-page {
  background-image: url('../../images/blog-stock-photo.jpg');

  /* Full height */
  height: 100vh;
  width: calc(100vw - 300px);

  position: absolute;
  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.tab-content {
  margin: 20px;
  padding-top: 10px;
  height: 100%;
}

// remove lower z-index on modal, up z-index on toast message
.toast-message {
  z-index: 100000 !important;
}
.ui.dimmer {
  z-index: 10;
}
.ui.button.primary {
  // VeryDark Blue
  background: #233349;
  color: white;

  &:hover {
    background: #233349;
  }

  // covault blue
  // background: rgb(31, 16, 242);
  // color: white;

  // &:hover {
  //   background: rgb(31, 16, 242);
  // }
}
.ui.table,
.ui.form {
  font-family: 'Primary', Verdana, Tahoma;
}

.ui.form .disabled.field,
.ui.form .disabled.fields .field,
.ui.form .field :disabled {
  opacity: 0.9;
}
