.update-billing-modal {
  width: 60%;
  margin: 0 auto;
  .credit-card-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;

    .heading {
      display: flex;
      align-items: center;

      .radio-outer {
        height: 20px;
        width: 20px;
        border-radius: 10px;
        border-width: 2px;
        border-style: solid;
        border-color: #72bc00;
        display: flex;
        align-items: center;
        justify-content: center;

        .radio-inner {
          height: 10px;
          width: 10px;
          border-radius: 5px;
          background-color: #72bc00;
        }
      }

      .pay-by-card-title {
        margin-left: 8px;
      }
    }

    .credit-card-icons-container {
      display: flex;
      svg {
        margin-right: 8px;
      }
    }
  }

  .form-field {
    margin-bottom: 16px 0;
    .input-container {
      position: relative;
      border: 1px solid #ccc;
      border-radius: 4px;
      padding: 8px;

      .card-icon {
        position: absolute;
        top: 0;
        right: 0;
        padding: 8px;
        color: #aaa;
      }
    }
  }
}
