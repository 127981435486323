.facebook-11-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 1080px;
    height: 560.423px;
  }

  .image2 {
    position: absolute;
    top: 808.372px;
    left: 0;
    width: 350.01px;
    height: 272.628px;
  }

  .image3 {
    position: absolute;
    top: 808.372px;
    left: 364.995px;
    width: 350.01px;
    height: 272.628px;
  }

  .image4 {
    position: absolute;
    top: 808.372px;
    left: 730.49px;
    width: 350.01px;
    height: 272.628px;
  }

  .content-wrapper {
    display: flex;
    position: absolute;
    top: 580px;
    width: 100%;
    height: 200px;
    background-color: white;
    align-items: center;
    padding: 0 30px;

    &--header {
      width: 100%;
    }
  }

  .border {
    height: 200px;
    margin: 30px;
    width: 8px;
    background-color: #112f23;
  }

  .headline {
    font-size: 65px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;

    opacity: 0.8;
    background: transparent;
  }

  .address {
    font-size: 40px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    background: transparent;
  }

  .detail {
    font-size: 30px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  &.black {
    .address,
    .headline {
      color: black;
    }
    .border {
      border-left: 5px solid black;
    }
  }

  &.green {
    .address,
    .headline {
      color: #112f23;
    }

    .border {
      border-left: 5px solid #83bd41;
      background-color: #83bd41;
    }
  }
}
