.pem-template-3.page-1 {
  width: 612px;
  height: 792px;
  padding: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
}

.pem-template-3.page-2 {
  width: 612px;
  height: 792px;
  padding: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;

  .listing-desc-container {
    height: 150px;

    .description {
      line-height: 14px;
    }
  }

  .listing-info {
    .listing-intro {
      position: relative;
    }
  }
}
