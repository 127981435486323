.facebook-22-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 1080px;
    height: 660px;
  }

  .image2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 544px;
    height: 660px;
  }

  .image3 {
    position: absolute;
    top: 0;
    left: 544px;
    width: 544px;
    height: 660px;
  }

  .image4 {
    position: absolute;
    top: 0;
    right: 0;
    width: 544px;
    height: 660px;
  }

  .content-wrapper {
    text-align: center;
    display: flex;
    position: absolute;
    bottom: 5px;
    width: 100%;
    height: 300px;
    background-color: white;
  }

  .content {
    position: absolute;
    text-align: center;
    left: 350px;
    display: flex;
    position: absolute;
    &--header {
      padding: 5px;
      padding-top: 50px;
    }
  }

  .border {
    height: 175px;
    margin: 40px;
    background-color: #112f23;
    width: 8px;
  }

  .headline {
    font-size: 65px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;

    opacity: 0.8;
    background: transparent;
  }

  .address {
    font-size: 40px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    background: transparent;
  }

  .detail {
    font-size: 30px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .logo {
    margin-left: 40px;
    margin-top: 40px;
    padding-right: 30px;
    flex: 3;
  }

  &.black {
    .headline {
      color: black;
    }
    .border {
      border-left: 5px solid black;
    }
  }

  &.green {
    .headline,
    .address {
      color: #112f23;
    }
    .border {
      border-left: 5px solid #83bd41;
      background-color: #83bd41;
    }
  }
}
