.mac-template-7 {
  height: 612px;
  width: 792px;
  padding: 30px;
  // padding-top: 0;
  position: relative;
  overflow: hidden;

  .listing-intro {
    // padding-top: 20px;
  }

  .logo-fixed {
    height: 130px;
  }

  .mac-horizontal-window-poster {
    .photo-layout {
      margin: 0px;
      margin-top: 10px;

      .image-container {
        margin: 0px;

        .listing-photo {
          width: 360px !important;
        }
      }
    }
    .photo-layout .image-container:first-of-type {
      margin-right: 10px;
    }

    .info-photo-layout {
      margin-top: 10px;

      .listing-desc {
        .address {
          margin-bottom: 5px;
        }
      }
    }

    .agent-branding.mac {
      margin-top: 8px;
      .agent-photo {
        height: 105px;
      }

      .agent-info {
        .agent-name {
          line-height: 1.5em;
        }
      }
    }

    .legal {
      left: 140px;
    }
  }
}
