$green: #98d216;
$light-blue: #87aad5;
$standard-blue: #4a65aa;
$primary-dark-blue: #233349;

@font-face {
  font-family: 'Avenir';
  src: url(/fonts/Avenir.ttc) format('truetype');
}

@font-face {
  font-family: 'Primary';
  src: url(/fonts/TTNorms-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'UbuntuR';
  src: url(/fonts/Ubuntu-R.ttf) format('truetype');
}

@font-face {
  font-family: 'UbuntuB';
  src: url(/fonts/Ubuntu-B.ttf) format('truetype');
}

@font-face {
  font-family: 'UbuntuM';
  src: url(/fonts/Ubuntu-M.ttf) format('truetype');
}

@font-face {
  font-family: 'UbuntuL';
  src: url(/fonts/Ubuntu-L.ttf) format('truetype');
}

@font-face {
  font-family: 'LuxiaR';
  src: url(/fonts/Luxia-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'LuxiaB';
  src: url(/fonts/Luxia-Bold.otf) format('truetype');
}

@font-face {
  font-family: 'SocialR';
  src: url(/fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Social';
  src: url(/fonts/Lato-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'OvoR';
  src: url(/fonts/Ovo-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'MontserratL';
  src: url(/fonts/Montserrat-Light.otf) format('truetype');
}

@font-face {
  font-family: 'MontserratR';
  src: url(/fonts/Montserrat-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'MontserratM';
  src: url(/fonts/Montserrat-Medium.otf) format('truetype');
}

@font-face {
  font-family: 'MontserratSB';
  src: url(/fonts/Montserrat-SemiBold.otf) format('truetype');
}

@font-face {
  font-family: 'CalibriBold';
  src: url(/fonts/CalibriBold.ttf) format('truetype');
}

body {
  color: #414042;
}

a {
  color: #3d3d3d;
}
a:hover {
  color: #3d3d3d;
}

.app-container .sidebar {
  color: #ebedef;
  width: 300px !important;
}

html,
body {
  height: 100%;
}

body {
  font-family: 'Primary', Verdana, Tahoma;
}
#root,
.App {
  position: relative;
  background-color: #ebedef;
  .menu-icon {
    position: absolute;
    cursor: pointer;
    z-index: 100;
    @media screen and (min-width: 700px) {
      display: none;
    }
  }
  .menu-icon-expand {
    left: 320px;
    top: 10px;
  }
  .menu-icon-collapse {
    left: 20px;
    top: 10px;
  }
  .sidebar {
    padding: 0 !important;
    height: 100vh;
    background-color: #fff;
    border-right: none !important;

    .top-logo {
      text-align: center;
      margin-top: 20px;
    }

    .button {
      text-align: center;
      width: inherit;
      background-color: transparent !important;
    }

    .nav-links {
      text-align: center;
      position: absolute;
      top: 200px;
      width: 300px;

      a {
        display: block;

        .button {
          height: 125px;
          width: 300px;
          width: inherit;
          background-color: transparent !important;
        }
      }

      & .selected {
        background-color: #ebedef;
        height: inherit;
        width: 100%;
      }
    }

    .bottom {
      display: inline-flex;
      position: absolute;
      bottom: 16px;
      width: 100%;
    }
  }

  .app-container {
    min-height: calc(100% - 70px);
    overflow-y: hidden;
  }

  .app-row {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .app-container .main-container {
    // width: calc(100% - 300px) !important;
    overflow: auto;
    padding: 50px;
    height: 100vh;
  }

  .app-container .main-container .page-header {
    text-align: center;
  }
}

.text-center {
  text-align: center;
}

.editable {
  border: none;
  width: 100%;
  height: inherit;

  &:hover {
    color: #000;
    background-color: #f8f8f8;
    outline: 1px solid grey;
  }
}

.no-border {
  border: none;
}

.inhert-style {
  font-size: inherit;
  font-family: inherit;
  color: inherit;
  text-align: inherit;
  background: inherit;
  letter-spacing: inherit;
  border: none;
}

.template {
  --font-color: #3d3d3d;
  --mac-font-color: #0054a5;
  --mac-font-color-grey: #191919;
  margin: auto;
}

.template-dark {
  --font-color: #414042 !important;
}

.ui-only-outline {
  outline: grey 1px solid;
}

.template-actions {
  text-align: right;

  .ui.button:hover {
    opacity: 0.7;
    transition: 0.3s;
  }
}

.mt-1 {
  margin-top: 1rem !important;
}

.ui.button:hover {
  opacity: 0.7;
  transition: 0.3s;
}

.ui.button.primary {
  background: $primary-dark-blue; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    $primary-dark-blue,
    $primary-dark-blue
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, $primary-dark-blue, $primary-dark-blue);

  &.disabled {
    background: $primary-dark-blue;
  }
}

.ui.button.blue {
  background: $light-blue; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, $light-blue, $light-blue); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    $light-blue,
    $light-blue
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  &.disabled {
    background: $light-blue;
  }
}

.ui.button.standard {
  background: $standard-blue; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, $standard-blue, $standard-blue); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    $standard-blue,
    $standard-blue
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.ui.button.black {
  background: $primary-dark-blue; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, $primary-dark-blue, $primary-dark-blue); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    $primary-dark-blue,
    $primary-dark-blue
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  &.disabled {
    background: $primary-dark-blue;
  }
}

.ui.button.green {
  background-color: $green !important; /* fallback for old browsers */
  background: -webkit-linear-gradient(to top, $green, $green); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to top,
    $green,
    $green
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

.ui.positive.button {
  background: $green;
}

.ui.negative.button {
  background: $primary-dark-blue;
}

.edit-btn-hover {
  position: absolute;
  top: calc(50% - 17px);
  left: calc(50% - 50px);
  white-space: nowrap;
  opacity: 0;
  z-index: 1;
}

.social-templates-container {
  width: 1600px;
  margin: auto;
}

// placeholder if photo missing
.empty-placeholder {
  background-position: center center;
  background-repeat: no-repeat;
  position: absolute;
  width: inherit;
  height: inherit;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

.empty-placeholder:hover {
  outline: 1px solid #19472cd6;
}

.cv-icon {
  max-height: 38px;
  max-width: 44px;
}

.cv-ig-icon {
  max-height: 48px;
  max-width: 56px;
}

.noselect {
  -webkit-user-select: none; /* Chrome all / Safari all */
  -moz-user-select: none; /* Firefox all */
  -ms-user-select: none; /* IE 10+ */
  user-select: none; /* Likely future */
}
hr {
  border: 0;
}
.nowrap {
  white-space: nowrap;
}

.mt-100p {
  margin-top: 100px;
}

.mt-40p {
  margin-top: 40px;
}

.mt-150p {
  margin-top: 150px;
}

.mt-250p {
  margin-top: 250px;
}

.mb-50p {
  margin-bottom: 50px;
}

.ml-20p {
  margin-left: 20px;
}

.d-flex {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  &.justify-content-between {
    justify-content: space-between;
  }
}

.social-buttons {
  width: 225px;
  height: 225px;
}

.ui.tab.active,
.ui.tab.open {
  min-height: 90vh;
  height: fit-content;
}

.link-button {
  background: none !important;
  border: none;
  padding: 0 !important;
  /*optional*/
  font-family: arial, sans-serif;
  /*input has OS specific font-family*/
  color: #069;
  text-decoration: underline;
  cursor: pointer;
}

.center-title {
  margin-top: 50px;
  text-align: center;

  h1 {
    font-size: 35.5px;
  }
  .text {
    text-align: left;
    max-width: 595px;
    margin: 30px auto;
  }
}

.link-text {
  font-size: 0.85rem;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.underline-link {
  text-decoration: underline;

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
}

.form-message {
  margin: 15px 0;
}

.mb-4 {
  margin-bottom: 8px;
}

.mb-6 {
  margin-bottom: 12px;
}

.mb-8 {
  margin-bottom: 16px;
}

.mt-5 {
  margin-top: 5px !important;
}

.mr-4 {
  margin-right: 8px;
}
.mr-6 {
  margin-right: 12px;
}
.mr-8 {
  margin-right: 16px;
}
.p-20 {
  padding: 20px;
}

.flex {
  display: flex;
}
.justify-center {
  justify-content: center;
}
.space-between {
  justify-content: space-between;
}
.items-center {
  align-items: center;
}

.mt-4 {
  margin-top: 8px;
}
.mt-6 {
  margin-top: 12px;
}
.mt-8 {
  margin-top: 16px;
}
.mt-10 {
  margin-top: 20px;
}
.mt-12 {
  margin-top: 24px;
}
.text-center {
  text-align: center !important;
}
.text-right {
  text-align: right !important;
}

.bg-white {
  background-color: white;
}
.text-white {
  color: white;
}

.hide-scroll-number-input {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }

  input[type='number'] {
    -moz-appearance: textfield; /* Firefox */
  }
}

.outlined-btn.disabled-btn {
  cursor: not-allowed;
  background: gainsboro !important;
  border-color: gainsboro !important;
  color: #707070 !important;
  user-select: none;
}

.template-td {
  width: 200px !important;
}
.brochure-templates-container .column {
  width: 50%;
}
.brochure-templates-container h3.ui.header {
  max-width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: auto;
  margin-bottom: 30px;
}
.pem-template-2 {
  background: white;
}
.pem-template-3 {
  background: white;
}
.listing-info--header .logo img,
.listing-info--header .logo--white img {
  height: 100%;
  object-fit: contain;
  object-position: left;
}

@media screen and (max-width: 768px) {
  .brochure-templates-container .column {
    width: 100%;
  }
}
