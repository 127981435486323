.billing-history-container {
  .date-filters-container {
    padding: 16px 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .date-input {
      padding: 4px;
      font-size: 16px;
    }
  }

  .table-container {
    margin-top: 8px;
    .download_invoice_link {
      color: skyblue;
      cursor: pointer;
      font-weight: 500;
    }

    .status-cell {
      text-transform: capitalize;
    }
  }
}
