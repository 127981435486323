.mac-facebook-1-wrapper {
  .image1 {
    position: absolute;
    top: 130px;
    left: 134px;
    width: 946px;
    height: 628px;
  }

  .headline {
    position: absolute;
    top: 793.784px;
    left: 144px;
    width: 660px;

    font-size: 70px;
    font-family: 'LuxiaR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;
    color: rgb(3, 44, 143);
    background: transparent;
  }

  .address {
    position: absolute;
    top: 895px;
    left: 144px;
    width: 660px;
    height: 26.384px;

    font-size: 34px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(3, 44, 143);
    background: transparent;
  }

  .logo {
    position: absolute;
    top: 800.216px;
    left: 852.402px;
    width: 216.019px;
    height: auto;
  }
}
