.whistler-facebook-7-wrapper {
  width: 1200px;
  height: 628px;

  .image1 {
    position: absolute;
    width: inherit;
    height: inherit;
  }

  .overlay-dark {
    position: absolute;
    left: 0;
    width: 260px;
    height: 100%;
  }

  .content-wrapper {
    position: absolute;
    width: 300px;
    height: 508px;
    left: 60px;
    top: 60px;
    padding: 50px 20px;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;

    font-family: 'AcuminR';
    background-color: white;

    hr {
      width: 25%;
      height: 3px;
      background-color: var(--whistler-red);
      margin-bottom: 40px;
    }

    .headline {
      font-size: 40px;
      color: var(--whistler-red);
    }

    .address,
    .listingDetails {
      width: 100%;
      font-size: 14px;
      font-family: 'AcuminR';
      color: var(--whistler-grey);
    }

    .logo {
      width: 150px;
      margin-top: 120px;
    }
  }
}
