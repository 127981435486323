.onboarding-layout {
  font-family: 'MontserratR';
  --dark-blue: #233349;
  --light-blue: #4a65aa;
  --slate-grey: #87aad5;
  --green-yellow: #9bd216;
  --background: #e5e5e5;
  --link: #51a9ce;
  --navbarHeight: 72px;
  min-height: 100vh;

  .navbar {
    height: var(--navbarHeight);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 100;
    background: white;
  }

  .content-area {
    padding-top: 24px;
    width: 100%;
    height: 100%;
    background-color: var(--background);
    margin-top: var(--navbarHeight);
  }

  .content-area.background-attachment {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-blend-mode: soft-light !important;
  }

  .list-container {
    li > a {
      padding-bottom: 4px;
    }
    li > a.active {
      border-bottom: 3px solid var(--link);
    }
  }
}
