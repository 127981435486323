.whistler-social-13-wrapper {
  width: 1080px;
  height: 1080px;

  .image1 {
    position: absolute;
    width: inherit;
    height: inherit;
  }

  .overlay-light {
    position: absolute;
    right: 0;
    width: 450px;
    height: 100%;
  }

  .content-wrapper {
    position: absolute;
    width: 480px;
    height: 80%;
    left: 50%;
    top: 10%;
    padding: 80px 50px;

    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: space-between;

    font-family: 'AcuminR';
    background-color: var(--whistler-black);
    color: white;

    .headline {
      font-size: 75px;
    }

    hr {
      width: 100%;
      height: 3px;
      background-color: var(--whistler-red);
    }

    .address,
    .listingDetails {
      font-size: 27px;
      font-family: 'AcuminR';
      color: white;
      min-height: 1.3em;
    }

    .address {
      margin-bottom: 80px;
    }

    .listingDetails {
      margin-bottom: 20px;
    }

    .logo {
      margin-top: 120px;
      width: 250px;
      img {
        margin: auto;
      }
    }
  }
  .content-wrapper .editable {
    color: #fff;
  }
  .content-wrapper .editable:hover {
    color: #000;
  }
}
