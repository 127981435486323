/* UPLOAD IMAGE */

.image-container {
  position: relative;

  .listing-photo {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .upload-button {
    position: absolute;
    top: calc(50% - 17px);
    left: calc(50% - 50px);
    opacity: 0;
  }

  &:hover {
    .upload-button {
      opacity: 1;
    }
    .listing-photo {
      opacity: 0.5;
    }
    .listing-photo .empty-placeholder {
      outline: 1px solid #19472cd6;
    }
  }
}
