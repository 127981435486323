.whistler-facebook-1-wrapper {
  width: 1200px;
  height: 628px;

  .image1 {
    position: absolute;
    width: inherit;
    height: inherit;
  }

  .content-wrapper {
    position: absolute;
    width: 300px;
    height: 100%;
    padding: 60px 50px;
    background: rgba(0, 0, 0, 0.7);
    text-align: left;
    font-family: 'AcuminR', Verdana, Tahoma;
    color: white;
  }

  .headline {
    font-size: 33px;
  }

  hr {
    width: 50px;
    height: 2px;
    background: var(--whistler-red);
    margin: 40px 0;
  }

  .address,
  .listingDetails {
    margin-bottom: 30px;
    font-size: 16px;
  }

  .logo {
    position: relative;
    top: 210px;
    width: 150px;
    margin: auto;
  }
  .content-wrapper .editable {
    color: #fff;
  }
  .content-wrapper .editable:hover {
    color: #000;
  }
}
