.whistler-social-16-wrapper {
  .image1 {
    position: relative;
    width: inherit;
    height: inherit;

    .image-content {
      background: var(--whistler-black);
    }

    .background-image {
      opacity: 0.55 !important;
    }
  }

  .quotes {
    position: absolute;
    top: 15%;
    width: 100%;
    height: 70%;
    background-image: url('../../../images/whistler/quotes_full.png');
    background-size: contain;
    background-repeat: no-repeat;
  }

  .content-wrapper {
    position: absolute;
    top: 210px;
    left: 20%;
    width: 60%;
    height: 660px;
    text-align: center;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: white;

    .testimonial {
      ::placeholder {
        color: white;
      }
      & :hover ::placeholder {
        color: black;
      }

      font-size: 65px;
      font-family: 'AcuminR';
      textarea {
        line-height: 1.5em;
      }
    }

    .client-name {
      font-size: 25px;
      font-family: 'AcuminR';
    }
    .editable {
      color: #fff;
    }
    .editable:hover {
      color: #000;
    }
  }
}
