.message-container {
  position: absolute;
  z-index: 10;
  width: 620px;

  background: white;
  border-radius: 5px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
}
.message-container .close-message {
  position: absolute;
  top: 0px;
  right: 0px;
  background: none;
  &:hover {
    background: none;
  }
}
.message-wrapper {
  padding: 20px;
}
.message-header {
  width: 85%;
}
.message-title {
  margin-bottom: 10px;
  font-family: 'Lato', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif';
  font-size: 1.5em;
  font-weight: bold;
}
.message-note {
  margin-bottom: 20px;
  font-family: 'Lato', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif';
  font-size: 1.25em;
}
.message-loading {
  color: #3d3d3d;
  background: #eaedef;

  margin-top: 5px;
  width: 100%;
  padding: 1em 2em;
  padding: 1.5em 2em;
  text-align: left;
}
