.whistler-social-8-wrapper {
  .multi-photo-layout {
    height: 710px;
    display: flex;
    justify-content: space-between;

    .three-photo-container {
      display: flex;
      flex-direction: column;
      width: 320px;
      justify-content: space-between;

      .photo {
        position: relative;
        height: 230px;
      }
    }
    .image1 {
      position: relative;
      width: 750px;
      height: 710px;
    }
  }

  .details-container {
    background-color: white;
    height: 370px;
    display: flex;
    align-items: center;
    padding: 0 80px;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 70%;
      width: 100%;

      .headline {
        font-size: 60px;
        font-family: 'AcuminB';
        color: var(--whistler-red);
        background: transparent;
      }

      hr {
        width: 20%;
        height: 3px;
        background-color: var(--whistler-red);
        margin: 10px 0 30px;
      }

      .address {
        font-size: 35px;
        font-family: 'AcuminR';
        color: var(--whistler-grey);
      }

      .detail {
        font-size: 25px;
        font-family: 'AcuminR';
        color: var(--whistler-grey);
      }
    }
    .logo {
      height: 70%;
      display: grid;
      align-items: end;

      img {
        width: 200px;
        height: auto;
      }
    }
  }
}
