.mac-window-poster {
  .logo-fixed {
    background-color: #0054a5;
    width: 130px;
    height: 125px;
    position: absolute;
    top: 0;
    left: 60px;
    z-index: 1;
    padding-top: 20px;
  }

  .info-photo-layout {
    display: flex;
    height: 215px;
    justify-content: space-between;
    margin-top: 10px;
  }

  .info-photo-layout .stacked {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .listing-desc {
    line-height: 24px;
    width: 350px;
    position: relative;
    // padding-left: 20px;
    padding-right: 10px;

    .address {
      font-size: 17px;
      font-family: 'UbuntuM', Verdana, Tahoma;
      color: var(--mac-font-color);
      margin-bottom: 10px;
      margin-top: 15px;
      line-height: 1em;
    }

    .description {
      font-family: 'UbuntuL', Verdana, Tahoma;
      font-size: 9.5px;
      line-height: 13px;

      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .details {
      font-size: 10px;
      line-height: 2.5em;
      font-family: 'UbuntuM', Verdana, Tahoma;
      color: #3d464d;
      display: flex;
      .item {
        margin-right: 30px;
      }
    }

    &:hover .edit-btn-hover {
      opacity: 1;
    }

    &:hover > div,
    &:hover > span {
      opacity: 0.5;
    }
  }

  .two-agents {
    display: flex;

    .agent-branding.no-logo:first-of-type {
      margin-right: 10px;
      min-width: 49%;
    }

    .agent-contact {
      font-size: 9px;
    }
    .agent-branding.no-logo .agent-photo {
      min-width: 80px;
      max-width: 80px;
    }
    .agent-photo {
      top: 0 !important;
    }
    .agent-info {
      justify-content: flex-start !important;
      padding-top: 0 !important;
    }
  }

  .agent-branding.no-logo {
    top: 10px;

    .agent-photo {
      min-width: 85px;
      max-width: 85px;
      margin-top: 0;
      height: 110px;
    }

    .agent-info {
      width: 100%;
      justify-content: center;
      // padding-top: 5px;
      .agent-name {
        border-top: grey 1px solid;
        padding-top: 8px;
      }
      .agent-name,
      .agent-phone {
        font-size: 13.6px;
      }
      .agent-title,
      .agent-contact {
        font-size: 8.5px;
      }
    }

    .agent-phone {
      font-size: 16px;
    }
  }

  .legal {
    position: absolute;
    left: 123px;
    margin-top: 7px;
    font-size: 6px;
    font-family: 'UbuntuL', Verdana, Tahoma;
  }
}
.mac-template-6 {
  .legal {
    margin-top: 0px !important;
    bottom: 0px !important;
    left: 118px;
  }
}
