.vw-social-2-wrapper {
  .main-section {
    margin: auto;
    height: 100%;
    width: 100%;

    .grid-section {
      display: flex;
      height: 502px;

      .grid-left {
        flex: 0 0 30%;
        width: 30%;
        padding-right: 4px;

        .image-container-1,
        .image-container-2 {
          position: relative;
          width: 100%;
          height: 50%;
          .image {
            width: 100%;
            height: 100%;

            background: center no-repeat url('../../../images/camera.svg');
            background-size: 64px;
          }
        }
        .image-container-1 {
          padding-bottom: 4px;
        }
        .image-container-2 {
          padding-top: 4px;
        }
      }
      .grid-right {
        flex: 0 0 70%;
        width: 70%;
        padding-left: 4px;
        position: relative;
        .image {
          width: 100%;
          height: 100%;
          background: center no-repeat url('../../../images/camera.svg');
          background-size: 64px;
        }
      }
    }
    .text-section {
      display: flex;
      justify-content: space-between;
      padding: 20px;

      .text-grid .headline {
        color: #1c76a0;
        margin-top: 0;
        font-size: 35px;
        font-weight: 400;
        margin-bottom: 5px;
        font-family: 'MontserratM';
      }
      .text-grid .address {
        color: #a69594;
        margin-bottom: 0;
        margin-top: 0;
        font-size: 20px;
        font-family: 'MontserratR';
      }
      .text-grid {
        padding-left: 50px;
        margin-left: 7%;
        margin-right: 20px;
        width: 100%;
        border-left: 3px solid #1c76a0;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .logo-image {
        width: 256px;
        height: 90px;
        margin-right: 12px;
        object-fit: contain;
      }
    }
  }
}
