// .theme-container {
//   .theme-creator-btn {
//     margin-top: 16px;
//   }
// }

.google-picker {
  width: 90% !important;
}

.color-picker-grid {
  display: flex;
  @media screen and (max-width: 1000px) {
    display: block;
  }
}
