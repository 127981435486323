.facebook-7-wrapper {
  .title {
    position: absolute;
    top: 188px;
    left: 0;
    width: 100%;

    font-size: 64px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 2px;
    background: transparent;
  }

  hr {
    position: absolute;
    top: 297.566px;
    left: 450.765px;
    width: 178px;
    height: 5px;
  }

  .content-wrapper {
    position: absolute;
    top: 420.09px;
    left: 315.026px;
    width: 449.947px;

    .content {
      font-size: 33px;
      font-family: 'OvoR', Verdana, Tahoma;
      text-align: center;
      width: 110%;
      letter-spacing: 2px;
      color: #747474;
      background: transparent;
    }

    .clients {
      font-size: 20px;
      margin-top: 30px;
      font-family: 'OvoR', Verdana, Tahoma;
      text-align: center;
      letter-spacing: 0;
      background: transparent;
    }
  }

  .logo {
    position: absolute;
    top: 892.872px;
    left: calc(50% - 90px);
  }

  &.black {
    .title,
    .clients {
      color: black;
      input {
        color: black;
      }
    }
    hr {
      color: black;
      background: black;
    }
  }

  &.green {
    .title,
    .clients {
      color: #112f23;
      input {
        color: #112f23;
      }
    }

    hr {
      color: #112f23;
      background: #112f23;
    }
  }
}
