.whistler-brochures-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .social-thumbnail {
    width: 35%;
    margin-bottom: 75px;

    .ui-only-outline {
      margin: 0;
    }

    h3 {
      white-space: unset;
    }
  }
}
