.manage-users {
  .plan-stats-container {
    margin-top: 24px;
    .stat-row {
      margin: 4px 0;
      span {
        line-height: 22px;
      }
      .heading {
        font-weight: bold;
        font-size: 16px;
      }

      .active-status {
        margin-left: 16px;
        color: green;
        border-color: green;
        padding: 4px 8px;
        text-transform: uppercase;
        font-size: 12px;
        background-color: greenyellow;
        font-weight: bold;
        border-radius: 4px;
      }

      .cancelled-status {
        margin-left: 16px;
        color: gainsboro;
        border-color: rgb(5, 5, 5);
        padding: 4px 8px;
        text-transform: uppercase;
        font-size: 12px;
        background-color: tomato;
        font-weight: bold;
        border-radius: 4px;
      }
    }
  }
}
