.mac-facebook-10-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 712px;
    height: 529.823px;
  }

  .image2 {
    position: absolute;
    top: 0;
    left: 736px;
    width: 344px;
    height: 254.149px;
  }

  .image3 {
    position: absolute;
    top: 275.674px;
    left: 736px;
    width: 344px;
    height: 254.149px;
  }

  .image4 {
    position: absolute;
    top: 550.762px;
    left: 0;
    width: 344px;
    height: 254.149px;
  }

  .image5 {
    position: absolute;
    top: 825.851px;
    left: 0;
    width: 344px;
    height: 254.149px;
  }

  .image6 {
    position: absolute;
    top: 825.851px;
    left: 368px;
    width: 344px;
    height: 254.149px;
  }

  .image7 {
    position: absolute;
    top: 825.851px;
    left: 736px;
    width: 344px;
    height: 254.149px;
  }

  .content-wrapper {
    position: absolute;
    top: 550.762px;
    left: 368px;
    width: 712px;
    height: 254.149px;
    background-color: rgb(239, 234, 231);
    opacity: 1;
  }

  .headline {
    position: absolute;
    top: 585px;
    left: 406px;
    width: 470px;

    font-size: 70px;
    font-family: 'LuxiaR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;
    color: rgb(3, 44, 143);
    background: transparent;
  }

  .address {
    position: absolute;
    top: 690.588px;
    left: 406px;
    width: 435px;

    font-size: 34px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .logo {
    position: absolute;
    top: 590px;
    left: 900px;
    width: 207.497px;
    height: 168.374px;
  }
}
