.public-layout {
  --navbarHeight: 72px;
  width: 100vw;
  max-height: 100%;

  .navbar-container {
    height: var(--navbarHeight);
    .navbar {
      height: var(--navbarHeight);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
      z-index: 100;
      background: white;

      display: flex;
    }
  }

  .main-content {
    margin: 0 auto;
    width: 90%;
    margin-top: var(--navbarHeight);
  }
}

@media screen and (min-width: 992px) {
  .public-layout {
    .main-content {
      width: 45%;
    }
  }
}
