.whistler-social-12-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;

  .image1 {
    position: relative;
    width: 50%;
    height: 570px;
  }

  .details-container {
    background-color: var(--whistler-black);
    height: 510px;
    width: 50%;
    display: flex;
    align-items: center;
    padding: 0 30px;

    .content {
      display: flex;
      flex-direction: column;
      text-align: center;
      color: white;
      position: relative;

      .headline {
        font-size: 80px;
        font-family: 'AcuminB';
        margin-bottom: 20px;
      }

      .address {
        font-size: 34px;
        font-family: 'AcuminR';
      }

      .editable {
        color: #fff;
      }
      .editable:hover {
        color: #000;
      }
    }
  }

  .image2 {
    position: relative;
    width: 50%;
    height: 650px;
  }

  .details-container-second {
    display: flex;
    width: 50%;
    height: 430px;
    align-items: center;

    .border {
      border-left: 4px solid var(--whistler-red);
      margin: 0 50px 0 110px;
      height: 30%;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 25%;
      color: var(--whistler-grey);
      font-family: 'AcuminR';

      .pricedAt {
        font-size: 40px;
        width: 80%;
      }

      .price {
        font-size: 35px;
        width: 80%;
      }
    }
  }
}
