.mac-facebook-3-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .content-wrapper {
    position: absolute;
    top: 478px;
    left: 520px;
    width: 560px;
    height: 405.535px;
    background-color: rgb(3, 44, 143);
    opacity: 0.9;
  }

  .headline {
    position: absolute;
    top: 522.454px;
    left: 566.174px;
    width: 500px;

    font-size: 60px;
    font-family: 'LuxiaR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;
    color: #fff;
    background: transparent;
  }

  hr {
    position: absolute;
    top: 615.535px;
    left: 566.174px;
    width: 93.859px;
    height: 12px;
    color: #fff;
    background: #fff;
  }

  .address {
    position: absolute;
    top: 670.315px;
    left: 567.523px;
    width: 490.168px;
    height: 29.487px;

    font-size: 38px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: #fff;
    background: transparent;
  }

  .detail {
    position: absolute;
    top: 776px;
    left: 567.523px;
    width: 300px;
    height: 68.688px;

    font-size: 28px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: #fff;
    background: transparent;

    textarea {
      height: 80px;
      line-height: 38px;
    }
  }

  .logo {
    position: absolute;
    top: 719.541px;
    left: 879.891px;
    width: 181.497px;
    height: 147.276px;
  }
}
