.vw-social-template-4-wrapper {
  position: relative !important;
  overflow: hidden !important;
  height: 1080px;
  width: 1080px;

  .image1 {
    height: 1080px;
    width: 1080px;
    object-fit: cover;
    background: center no-repeat url('../../../images/camera.svg');
    background-size: 64px;
  }
  .headline {
    position: absolute;
    top: 50%;
    left: 50%;
    background: #165f86ab;
    color: white;
    font-weight: 700;
    font-family: 'MontserratM';
    font-size: 80px;
    width: 142%;
    text-align: center;
    transform: translate(-50%, -50%) rotate(-45deg);
    letter-spacing: 2px;
    margin: 0;

    textarea {
      font-size: 88px;
      font-weight: bold;
      color: white;
      text-transform: uppercase;
    }
    textarea:hover {
      color: black;
    }
  }

  .logo-img {
    position: absolute;
    width: auto;
    height: 120px;
    top: 15px;
    object-fit: contain;
    left: 15px;
  }
}
