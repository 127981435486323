.facebook-8-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 1080px;
    height: 100%;
  }

  .content-wrapper {
    display: flex;
    position: absolute;
    top: 638.844px;
    width: 100%;
    height: 302.312px;
    // background-color: #112F23 !important;
    opacity: 0.8;
    align-items: center;

    &--header {
      padding: 5px;
      width: 100%;
    }
  }

  .headline {
    font-size: 65px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;
    color: white !important;
    // opacity: 0.8;
    background: transparent;
  }

  .border {
    height: 240px;
    margin: 30px;
    background-color: white;
    width: 8px;
  }

  .address {
    font-size: 40px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: white;
    background: transparent;
  }

  .detail {
    font-size: 30px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: white;
    background: transparent;
  }

  .logo {
    margin-left: 30px;
  }

  &.black {
    .headline {
      color: white;
    }
    .border {
      border-left: 5px solid white;
    }
  }

  &.green {
    .headline {
      color: #112f23;
    }
    .border {
      border-left: 5px solid #83bd41;
      background-color: #83bd41;
    }
  }
}
