.select-pricing {
  font-family: 'MontserratR';
  padding-bottom: 16px;

  .heading {
    clip-path: polygon(0 0, 94% 0, 100% 100%, 6% 100%);
    background-color: var(--slate-grey);
    font-weight: bold;
    font-family: 'MontserratM';
    color: white;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
  }

  .choose-plan-container {
    border: 1px solid #c4c4c4;
    width: 80%;
    margin: 0 auto;

    .content {
      background-color: white;
      .border-right {
        border-right: 1px solid #c4c4c4;
      }
      .header-1,
      .btn-1 {
        background-color: #4a65aa;
      }
      .header-2,
      .btn-2 {
        background-color: #9bd216;
      }
      .header-3,
      .btn-3 {
        background-color: #233349;
      }

      .price-1 {
        color: #4a65aa;
      }
      .price-2 {
        color: #9bd216;
      }
      .price-3 {
        color: #233349;
      }
    }
  }

  @media screen and (max-width: 768px) {
    .choose-plan-container {
      width: 100% !important;
    }
  }
}
