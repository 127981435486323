.brochure-share-link {
  .loading-alert {
    background: #39393abd;
    padding: 10px;
    color: white;
    position: absolute;
    top: 73px;
    white-space: initial;
    width: 411px;
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    border-radius: 4px;
    z-index: 10;
  }
}
