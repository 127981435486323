.preview-list-container {
  margin: 50px 100px;

  h1.preview-list-header {
    position: relative;
    text-align: center;
    margin-bottom: 50px;
  }

  .social-thumbnail {
    width: 100%;
    height: 100%;
    position: relative;

    h3 {
      white-space: nowrap;
      overflow: hidden;
      text-align: center;
    }

    .social-thumbnail-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
    }

    &:hover .scale-wrapper {
      opacity: 0.7;
    }
  }

  .empty-spacer {
    height: 50px;
  }
}
