.color-container {
  height: 20px;
  align-items: center;
}
.color-div {
  height: 16px;
  width: 16px;
  margin-right: 12px;
  border: 1px solid #ccc;
}
.loading-container {
  margin: 16px;
}
