.facebook-4-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #fff;
    opacity: 0.8;
  }

  .headline {
    position: absolute;
    top: 327.311px;
    left: 20px;
    width: 1040px;

    font-size: 84px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 2px;
  }

  hr {
    position: absolute;
    top: 492.57px;
    left: 493.071px;
    width: 93.859px;
    height: 12px;
  }

  .address {
    position: absolute;
    top: 602.009px;
    left: 20px;
    width: 1040px;

    font-size: 43px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 0;
  }

  .detail {
    position: absolute;
    top: 664.451px;
    left: 20px;
    width: 1040px;

    font-size: 30px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 0;
    background: transparent;
  }

  .logo {
    text-align: canter;
    position: absolute;
    bottom: 5%;
    left: 40%;
  }

  &.black {
    .address,
    .detail,
    .headline {
      color: black;
    }
    hr {
      color: black;
      background: black;
    }
  }

  &.green {
    .address,
    .detail,
    .headline {
      color: #112f23;
    }

    hr {
      color: #83bd41;
      background: #83bd41;
    }
  }
}
