.mac-facebook-5-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 535.313px;
    height: 535.313px;
  }

  .image2 {
    position: absolute;
    top: 0;
    left: 544.687px;
    width: 535.313px;
    height: 535.313px;
  }

  .image3 {
    position: absolute;
    top: 544.687px;
    left: 0;
    width: 535.313px;
    height: 535.313px;
  }

  .image4 {
    position: absolute;
    top: 544.687px;
    left: 544.687px;
    width: 535.313px;
    height: 535.313px;
  }

  .content-wrapper {
    position: absolute;
    top: 438.844px;
    left: 113.202px;
    width: 853.596px;
    height: 202.312px;
    background-color: rgb(3, 44, 143);
    opacity: 0.9;
  }

  .headline {
    position: absolute;
    top: 477.353px;
    left: 163.141px;
    width: 562px;

    font-size: 68px;
    font-family: 'LuxiaR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;
    color: #fff;
    background: transparent;
  }

  .address {
    position: absolute;
    top: 568.289px;
    left: 163.141px;
    width: 562px;

    font-size: 33px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: #fff;
    background: transparent;
  }

  .logo {
    position: absolute;
    top: 455.813px;
    left: 746.798px;
    width: 207.497px;
    height: auto;
  }
}
