.choose-plan-container {
  box-sizing: border-box !important;
  font-family: 'MontserratR';
  .modal-content {
    margin: 0;
    padding: 0;

    .content {
      display: flex;
      width: 100%;
      height: 480px;

      .plan-box-2 {
        border-left: 1px solid #ccc;
        border-right: 1px solid #ccc;
      }
      .plan-box {
        text-align: center;
        width: 33.33%;
        .content-header {
          color: white;
          height: 60px;
          display: flex;
          justify-content: center;
          align-items: center;

          h1 {
            font-size: 24px;
            font-weight: 500;
            text-transform: uppercase;
          }
        }

        .header-1 {
          background-color: #4a65aa;
        }
        .header-2 {
          background-color: #9bd216;
        }
        .header-3 {
          background-color: #233349;
        }

        .main-content {
          height: 408px;
          padding: 24px 0;
          display: flex;
          justify-content: space-between;
          flex-direction: column;
          align-items: center;

          .pricing-section {
            padding: 16px 0;
            position: relative;

            .price {
              font-size: 34px;
              font-weight: bold;

              sup {
                font-weight: 500;
              }
            }

            .price-1 {
              color: #4a65aa;
            }
            .price-2 {
              color: #9bd216;
            }
            .price-3 {
              color: #233349;
            }

            .pricing-users {
              font-size: 14px;
              font-weight: 500;
              margin-top: 36px;
            }
          }

          .plan-features {
            ul {
              padding: 16px 0;
              font-size: 15px;

              li {
                margin: 4px 0;
                padding: 4px 0;
                text-transform: capitalize;
              }
            }

            .add-more-users {
              padding: 8px 0;
            }
          }

          .action-btn-container {
            margin-top: 40px;
            .btn {
              cursor: pointer;
              padding: 10px 20px;
              border-radius: 4px;
              font-size: 16px;
              font-weight: 500;
              text-transform: uppercase;
              color: white;
            }

            .btn-1 {
              background-color: #4a65aa;
            }
            .btn-2 {
              background-color: #9bd216;
            }
            .btn-3 {
              background-color: #233349;
            }
            .current-plan {
              color: black;
              font-size: 18px;
              text-transform: unset;
              font-weight: bold;
              cursor: default;
            }
          }
        }
      }
    }

    .footer {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      background-color: #233349;
      color: white;
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      line-height: 20px;
    }
  }

  @media screen and (max-width: 576px) {
    .modal-content {
      .content {
        display: block !important;
        height: 100% !important;
      }

      .plan-box {
        width: 100% !important;
      }
    }
  }
}
