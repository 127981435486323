.facebook-9-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    right: 0;
    width: 754.013px;
    height: 704px;
  }

  .image2 {
    position: absolute;
    top: 0;
    right: 766px;
    width: 314px;
    height: 227.032px;
  }

  .image3 {
    position: absolute;
    top: 237.984px;
    right: 766px;
    width: 314px;
    height: 227.032px;
  }

  .image4 {
    position: absolute;
    top: 476.968px;
    right: 766px;
    width: 314px;
    height: 227.032px;
  }

  .headline {
    position: absolute;
    top: 749.888px;
    left: 72.552px;
    width: 900px;

    font-size: 65px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;
    background: transparent;
  }

  hr {
    position: absolute;
    top: 857.78px;
    left: 72.552px;
    width: 400px;
    height: 5px;
    background-color: #112f23;
  }

  .address {
    position: absolute;
    top: 931.746px;
    left: 72.552px;
    width: 746px;

    font-size: 40px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .detail {
    position: absolute;
    top: 992.739px;
    left: 72.552px;
    width: 750px;

    font-size: 30px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .logo {
    position: absolute;
    top: 890px;
    left: 850.079px;
    width: 200px;
    height: auto;
  }

  &.black {
    .headline {
      color: black;
    }
    hr {
      color: black;
      background: black;
    }
  }
  &.green {
    .headline {
      color: #112f23;
    }

    hr {
      color: #83bd41 !important;
      background: #83bd41 !important;
    }
  }
}
