.whistler-social-5-wrapper {
  .template-background-image {
    .image-content {
      background: var(--whistler-black);
    }

    .background-image {
      opacity: 0.6 !important;
    }
  }

  .content-wrapper {
    position: absolute;
    width: 80%;
    height: 80%;
    text-align: center;
    color: white;
    left: 10%;
    top: 10%;

    .border {
      border: 2px solid white;
      width: 100%;
      height: 100%;
    }

    .headline {
      width: 90%;
      margin: auto;
      margin-top: 130px;
      font-family: 'AcuminB', Verdana, Tahoma;
      font-size: 100px;
      line-height: 80px;
    }

    hr {
      width: 100px;
      height: 3px;
      background: var(--whistler-red);
      margin: 40px auto 135px;
    }

    .address,
    .listingDetails {
      margin: 0 auto 30px;
      font-family: 'AcuminR', Verdana, Tahoma;
      font-size: 40px;
      width: 80%;
      line-height: 80px;
    }
    .editable {
      color: #fff;
    }
    .editable:hover {
      color: #000;
    }
  }
}
