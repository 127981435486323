.whistler-facebook-4-wrapper {
  width: 1200px;
  height: 628px;

  .multi-photo-layout {
    height: 515px;
    display: flex;
    justify-content: space-between;

    .two-photo-container {
      display: flex;
      flex-direction: column;
      width: 320px;
      justify-content: space-between;

      .photo {
        position: relative;
        height: 254px;
        width: 375px;
      }
    }
    .image1 {
      position: relative;
      width: 818px;
      height: 515px;
    }
  }

  .details-container {
    background-color: white;
    height: 113px;
    display: flex;
    align-items: center;

    .border {
      border-left: 3px solid var(--whistler-red);
      margin: 0 40px 0 80px;
      height: 70%;
    }

    .content {
      display: flex;
      flex-direction: column;
      width: 80%;
      font-family: 'AcuminR';

      .headline {
        font-size: 37px;
        background: transparent;
        margin-bottom: 5px;
        color: var(--whistler-red);
      }

      .address {
        font-size: 19px;
        color: var(--whistler-grey);
      }
    }
  }
}
