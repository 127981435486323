.mac-facebook-16-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 256px;
    width: 944px;
    height: 628px;
  }

  .headline {
    position: absolute;
    top: 59.87px;
    left: 40px;
    width: 185px;

    font-size: 55px;
    font-family: 'LuxiaR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;
    color: rgb(3, 44, 143);
    background: transparent;
  }

  hr {
    position: absolute;
    top: 214px;
    left: 40px;
    width: 87.387px;
    height: 12px;
    color: rgb(3, 44, 143);
    background: rgb(3, 44, 143);
  }

  .logo {
    position: absolute;
    top: 487.298px;
    left: 50px;
    width: 144.25px;
    height: 117.038px;
  }
}
