.vw-social-template-2-wrapper {
  height: inherit;
  width: inherit;

  .header-image-container {
    position: relative;
    width: 1080px;
    height: 756px;

    .image1 {
      width: 1080px;
      height: 756px;
      background: center no-repeat url('../../../images/camera.svg');
      background-size: 64px;
    }
    .logo-img {
      position: absolute;
      width: auto;
      height: 150px;
      top: 20px;
      right: 20px;
      object-fit: contain;
    }
  }
  .sub-main {
    height: 324px;
    display: flex;
    flex-direction: column;

    .card-div {
      height: 324px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .headline {
        font-size: 45px;
        color: #1c76a0;
        margin-top: 0;
        margin-bottom: 20px;
        text-align: center;
        font-size: 45px;
        font-family: 'MontserratM';

        textarea {
          font-size: 45px;
          font-weight: 300;
          color: #515153;
        }
      }

      .address {
        font-size: 23px;
        margin-top: 0;
        color: #b7aaa9;
        margin-bottom: 10px;
        text-align: center;
        font-family: 'MontserratR';
        font-weight: 300;

        textarea {
          font-size: 23px;
          font-weight: 300;
          color: #515153;
        }
      }
    }
  }
}
