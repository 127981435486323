.facebook-12-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 1080px;
    height: 1080px;
  }

  .content-wrapper {
    text-align: center;
    position: absolute;
    top: 198.25px;
    left: 198.25px;
    width: 683.5px;
    background-color: #fff;
    opacity: 0.8;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .logo {
    // padding-top: 220px;
  }

  .headline {
    height: 150px;
    margin-top: 50px;
    font-size: 105.397px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 2px;
    background: transparent;
  }

  hr {
    width: 500px;
    height: 3px;
    background-color: #112f23;
  }

  &.black {
    .headline {
      color: black;
    }
    hr {
      color: black;
      background: black;
    }
    .content-wrapper {
      height: 650px;
      padding-bottom: 10%;
    }
  }

  &.green {
    .editable:hover {
      color: #112f23 !important;
    }

    .headline {
      color: #112f23;
    }

    .content-wrapper {
      height: 700px;
      padding-bottom: 10%;
    }

    hr {
      color: #83bd41 !important;
      background: #83bd41 !important;
    }
  }
}
