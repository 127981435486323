.universal-listing-feature {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  align-items: flex-start;

  .empty-placeholder {
    background-image: url('../../../../images/camera.svg');
    background-size: 64px;
  }

  .features {
    width: 375px;
    padding: 10px 5px 0;
    font-family: 'Primary', Verdana, Tahoma;
    position: relative;

    .title {
      font-weight: bold;
      margin-bottom: 10px;
      color: var(--font-color);
    }
    .feature-list {
      columns: 2;
      line-height: 22px;
    }

    .feature-list,
    .feature-inc {
      font-size: 10px;
    }

    .feature-list span {
      width: 50%;
    }

    .feature-list span.feature-name {
      width: 100px;
      display: inline-block;
    }

    .feature-inc {
      margin-top: 4px;
      line-height: 1.2em;

      span.feature-name {
        width: 90px;
        display: inline-block;
      }
    }

    .feature-inc {
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .feature-inc > div {
      margin-bottom: 9px;
    }

    &:hover .edit-btn-hover {
      opacity: 1;
    }

    &:hover > div {
      opacity: 0.5;
    }
  }
}
