.mac-facebook-15-wrapper {
  .image1 {
    position: absolute;
    top: 51px;
    left: 0;
    width: 1080px;
    height: 565.107px;
  }

  .image2 {
    position: absolute;
    top: 634.175px;
    left: 0;
    width: 346.747px;
    height: 221.338px;
  }

  .image3 {
    position: absolute;
    top: 634.175px;
    left: 366.626px;
    width: 346.747px;
    height: 221.338px;
  }

  .image4 {
    position: absolute;
    top: 634.175px;
    left: 733.253px;
    width: 346.747px;
    height: 221.338px;
  }

  .content-wrapper-1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 1080px;
    height: 35.4px;
    background-color: rgb(3, 44, 143);
  }

  .content-wrapper-2 {
    position: absolute;
    top: 872px;
    left: 0;
    width: 1080px;
    height: 208px;
    background-color: rgb(3, 44, 143);
  }

  .address {
    position: absolute;
    top: 930px;
    left: 72.276px;
    width: 790px;

    font-size: 36px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(255, 255, 255);
    background: transparent;
  }

  .sqft-icon {
    position: absolute;
    top: 997px;
    left: 70px;

    font-size: 30px;
    text-align: left;
    letter-spacing: 0;
    color: rgb(255, 255, 255);
    background: transparent;
  }

  .sqft-input {
    position: absolute;
    top: 1001.016px;
    left: 128px;
    width: 211.36px;

    font-size: 30px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(255, 255, 255);
    background: transparent;
  }

  .bath-icon {
    position: absolute;
    top: 997px;
    left: 350px;

    font-size: 30px;
    text-align: left;
    letter-spacing: 0;
    color: rgb(255, 255, 255);
    background: transparent;
  }

  .bath-input {
    position: absolute;
    top: 1001.016px;
    left: 411px;
    width: 100px;

    font-size: 30px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(255, 255, 255);
    background: transparent;
  }

  .bed-icon {
    position: absolute;
    top: 997px;
    left: 475px;

    font-size: 30px;
    text-align: left;
    letter-spacing: 0;
    color: rgb(255, 255, 255);
    background: transparent;
  }

  .bed-input {
    position: absolute;
    top: 1001.016px;
    left: 533px;
    width: 100px;

    font-size: 30px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(255, 255, 255);
    background: transparent;
  }

  .logo {
    position: absolute;
    top: 890.813px;
    left: 840px;
    width: 207.497px;
    height: 168.374px;
  }
}
