.editable-image {
  .image-content,
  .upload {
    width: 100%;
    height: 100%;
  }

  .upload {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: 0.3s;
    // visibility: hidden;

    .upload-wrapper {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(255, 255, 255, 0.5);
      opacity: 0.5;
    }

    .upload-button-wrapper {
      position: absolute;

      &.top-left {
      }

      &.top {
        top: 20px;
        left: calc(50% - 20px);
      }

      &.center {
        top: calc(50% - 17px);
        left: calc(50% - 50px);
        z-index: 1000;
      }

      &.left {
        top: calc(50% - 20px);
        left: 100px;
      }
    }
  }

  &:hover .upload {
    // visibility: visible;
    opacity: 1;
    transition: 0.3s;
  }
}
