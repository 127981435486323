.dropzone {
  padding: 20px;
  margin: auto;
  border: 4px dashed #a8a8a9;
  height: 200px;
  width: 70%;
  text-align: center;
  font-family: 'Primary', Verdana, Tahoma;

  .margin-top {
    margin-top: 10px;
  }

  .emphasis-text {
    font-size: 1rem;
  }
}

.modal-message {
  &.margin-bottom {
    margin-bottom: 35px;
  }

  text-align: center;
  font-family: 'Primary', Verdana, Tahoma;
}
