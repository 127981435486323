.whistler-social-17-wrapper {
  .details-container {
    display: flex;
    justify-content: center;
    background-color: var(--whistler-black);
    height: 100%;
    padding: 150px 60px;
    color: white;
    ::placeholder {
      color: white;
    }
    .editable {
      color: #fff;
    }
    .editable:hover {
      color: #000;
    }
    .testimonial {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 100%;
      width: 50%;
      text-align: center;

      .headline {
        font-size: 75px;
        font-family: 'AcuminB';
      }

      hr {
        width: 40%;
        height: 3px;
        background-color: var(--whistler-red);
        margin: 0px auto 100px auto;
      }

      .content {
        font-size: 35px;
        font-family: 'AcuminR';
      }

      .client-name {
        font-size: 20px;
        font-family: 'AcuminR';
      }
    }
  }
}
