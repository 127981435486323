.mac-facebook-7-wrapper {
  .title {
    position: absolute;
    top: 188px;
    left: 0;
    width: 100%;

    font-size: 64px;
    font-family: 'LuxiaR', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 2px;
    color: rgb(3, 44, 143);
    background: transparent;
    input {
      color: rgb(3, 44, 143);
    }
  }

  hr {
    position: absolute;
    top: 297.566px;
    left: 497.765px;
    width: 84.469px;
    height: 12px;
    color: rgb(3, 44, 143);
    background: rgb(3, 44, 143);
  }

  .content-wrapper {
    position: absolute;
    top: 356.09px;
    left: 315.026px;
    width: 449.947px;

    .quot1 {
      height: 60px;
      font-size: 114.007px;
      font-family: 'LuxiaB', Verdana, Tahoma;
      text-align: left;
      letter-spacing: 0;
      color: rgb(3, 44, 143);
      background: transparent;
    }

    .quot2 {
      height: 60px;
      margin-bottom: 20px;

      font-size: 114.007px;
      font-family: 'LuxiaB', Verdana, Tahoma;
      text-align: right;
      letter-spacing: 0;
      color: rgb(3, 44, 143);
      background: transparent;
    }

    .content {
      font-size: 33px;
      font-family: 'UbuntuR', Verdana, Tahoma;
      text-align: center;
      letter-spacing: 2px;
      color: rgb(3, 44, 143);
      background: transparent;
    }

    .clients {
      font-size: 20px;
      font-family: 'UbuntuM', Verdana, Tahoma;
      text-align: center;
      letter-spacing: 0;
      color: rgb(3, 44, 143);
      background: transparent;
    }
  }

  .logo {
    position: absolute;
    top: 892.872px;
    left: 459.333px;
    width: 161.335px;
    height: auto;
  }
}
