.whistler-facebook-6-wrapper {
  width: 1200px;
  height: 628px;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-content: space-between;
  background-color: white !important;

  .image1,
  .image2 {
    position: relative;
    width: 596px;
    height: 310px;
  }

  .details-container {
    width: 596px;
    height: 310px;
    position: relative;
    display: flex;
    align-items: center;

    .border {
      border-left: 2px solid var(--whistler-red);
      margin: 0 50px 0 100px;
      height: 50%;
    }

    & .content {
      width: 70%;
      z-index: 1000;
      left: 150px;
      background: transparent;
      position: absolute;
      visibility: visible;
      font-family: 'AcuminR';

      .headline {
        font-size: 35px;
        margin-bottom: 20px;
        color: var(--whistler-red) !important;
      }

      .address,
      .agent-details {
        font-size: 21px;
        color: var(--whistler-grey);
      }
    }
  }
}
