.mac-facebook-2-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 1080px;
    height: 1080px;
  }

  .content-wrapper {
    position: absolute;
    top: 198.25px;
    left: 198.25px;
    width: 683.5px;
    height: 683.5px;
    background-color: rgb(3, 44, 143);
    opacity: 0.9;
  }

  .headline {
    position: absolute;
    top: 284.643px;
    left: 208.25px;
    width: 663.5px;

    font-size: 105.397px;
    font-family: 'LuxiaR', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 2px;
    color: #fff;
    background: transparent;
  }

  hr {
    position: absolute;
    top: 565.355px;
    left: 493.071px;
    width: 93.859px;
    height: 12px;
    color: #fff;
    background: #fff;
  }

  .logo {
    position: absolute;
    top: 630px;
    left: 428.99px;
    width: 222.019px;
    height: auto;
  }
}
