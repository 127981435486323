.current-plan-container {
  padding: 24px;
  .team-plan-container {
    display: flex;
    justify-content: space-between;

    .plan-info {
      margin: 10px 0;
    }

    .active-status {
      margin-left: 16px;
      color: green;
      border-color: green;
      padding: 4px 8px;
      text-transform: uppercase;
      font-size: 12px;
      background-color: greenyellow;
      font-weight: bold;
      border-radius: 4px;
    }

    .cancelled-status {
      margin-left: 16px;
      color: gainsboro;
      border-color: rgb(5, 5, 5);
      padding: 4px 8px;
      text-transform: uppercase;
      font-size: 12px;
      background-color: tomato;
      font-weight: bold;
      border-radius: 4px;
    }
  }

  .heading {
    font-size: 22px;
    font-weight: bold;
    line-height: 22px;
    color: #233349;
    margin: 20px 0;
    display: flex;
    align-items: center;
  }

  .strong-text {
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
    margin: 5px 0;

    .normal-text {
      font-weight: normal;
    }
  }

  .extra-user-container {
    .manage-users-btn {
      margin-top: 24px;
    }
  }
}
