.vw-social-template-1-wrapper {
  width: 1080px;
  height: 1080px;

  .header-image-container {
    position: relative;
    width: 1080px;
    height: 594px;

    .image1 {
      width: 1080px;
      height: 594px;

      background: center no-repeat url('../../../images/camera.svg');
      background-size: 64px;
    }
  }
  .logo-img {
    position: absolute;
    width: auto;
    height: 150px;
    top: 20px;
    right: 20px;
    object-fit: contain;
  }
  .sub-main {
    height: 486px;
    display: flex;
    flex-direction: column;

    .card-div {
      background-color: #1c76a0;
      padding: 40px;
      height: 200px;

      .inputs-container {
        border-left: 4px solid white;
        margin-left: 40px;
        margin-right: 40px;
        padding-left: 60px;

        .address {
          font-size: 30px;
          font-weight: 500;
          color: white;
          margin-top: 0;
          margin-bottom: 20px;

          textarea {
            font-weight: 500;
          }
        }
      }

      .listingDetails {
        font-size: 22px;
        margin-top: 0;
        color: white;
        font-weight: 300;
        margin-bottom: 10px;

        textarea {
          font-size: 22px;
          font-weight: 300;
          color: #515153;
        }
      }
    }

    .grid-image-container {
      // flex: 1;
      display: flex;
      height: 286px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .grid {
        position: relative;
        // width: calc(100% / 3 + -5px);
        width: 355px;
        height: 286px;

        .grid-image {
          width: 100%;
          height: 100%;

          background: center no-repeat url('../../../images/camera.svg');
          background-size: 64px;
        }
      }
    }
  }
}
