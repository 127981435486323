.contact-admin-modal-container {
  padding: 36px 48px;
  font-weight: 400;
  background-color: rgb(255, 246, 228);
  color: orange;
  border-radius: 10px;
  p {
    text-align: center;
    font-family: 'MontserratR';
    line-height: 20px;
    margin-bottom: 4px;
    font-size: 15px;
  }
}
