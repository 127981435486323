.instagram-1-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(3, 44, 134);
  }

  .headline {
    position: absolute;
    top: 270px;
    left: 50px;
    width: 980px;

    font-size: 131.962px;
    font-family: 'LuxiaR', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 2px;
    color: #fff;
    background: transparent;
  }

  hr {
    position: absolute;
    top: 496px;
    left: 456px;
    width: 168px;
    height: 16.6px;
    color: #fff;
    background: #fff;
  }

  .address {
    position: absolute;
    top: 641px;
    left: 50px;
    width: 980px;

    font-size: 67.552px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 0;
    color: #fff;
    background: transparent;
  }

  .detail1 {
    position: absolute;
    top: 866px;
    left: 20px;
    width: 1040px;

    font-size: 47.129px;
    font-family: 'UbuntuR', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 0;
    color: #fff;
    background: transparent;
  }

  .detail2 {
    position: absolute;
    top: 945px;
    left: 20px;
    width: 1040px;

    font-size: 47.129px;
    font-family: 'UbuntuR', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 0;
    color: #fff;
    background: transparent;
  }

  .logo {
    position: absolute;
    top: 1524px;
    left: 377.126px;
    width: 325.747px;
    height: auto;
  }
}
