.instagram-3-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .content-wrapper {
    position: absolute;
    top: 1022px;
    left: 312px;
    width: 768px;
    height: 535.052px;
    background-color: rgb(3, 44, 143);
    opacity: 0.9;
  }

  .headline {
    position: absolute;
    top: 1074.258px;
    left: 372.922px;
    width: 700px;

    font-size: 79.163px;
    font-family: 'LuxiaR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;
    color: #fff;
    background: transparent;
  }

  hr {
    position: absolute;
    top: 1197.068px;
    left: 372.922px;
    width: 123.836px;
    height: 12.257px;
    color: #fff;
    background: #fff;
  }

  .address {
    position: absolute;
    top: 1269.343px;
    left: 374.702px;
    width: 646.722px;
    height: 38.905px;

    font-size: 50.137px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: #fff;
    background: transparent;
  }

  .detail {
    position: absolute;
    top: 1415.171px;
    left: 374.702px;
    width: 400px;
    height: 84.03px;

    font-size: 36.943px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: #fff;
    background: transparent;
    textarea {
      line-height: 50px;
      height: 98px;
    }
  }

  .logo {
    position: absolute;
    top: 1350px;
    right: -25px;
    width: 300px;
    height: auto;
  }
}
