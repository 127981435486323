.mac-facebook-8-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 1080px;
    height: 704px;
  }

  .headline {
    position: absolute;
    top: 749.888px;
    left: 72.552px;
    width: 900px;

    font-size: 65px;
    font-family: 'LuxiaR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;
    color: rgb(3, 44, 143);
    background: transparent;
  }

  hr {
    position: absolute;
    top: 857.78px;
    left: 72.552px;
    width: 93.775px;
    height: 12px;
    color: rgb(3, 44, 143);
    background: rgb(3, 44, 143);
  }

  .address {
    position: absolute;
    top: 931.746px;
    left: 72.552px;
    width: 746px;

    font-size: 40px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .detail {
    position: absolute;
    top: 992.739px;
    left: 72.552px;
    width: 750px;

    font-size: 30px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .logo {
    position: absolute;
    top: 928.872px;
    left: 868.079px;
    width: 159.335px;
    height: auto;
  }
}
