.whistler-social-templates-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .social-thumbnail {
    width: 30%;
    margin-bottom: 75px;
  }
}
