.mac-template-1.page-1 {
  width: 1224px;
  height: 792px;
  padding: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;

  .listing-desc-container {
    height: 127px;
  }
}

.mac-template-1.page-2 {
  width: 1224px;
  height: 792px;
  padding: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;
}
// .price {
//   font-weight: bold;
//   input {
//     font-weight: bold;
//   }
// }
// .listing-desc {
//   .address {
//     input {
//       // color: rgb(0, 84, 165);

//     }
//   }
// }
