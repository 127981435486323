.facebook-5-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 535.313px;
    height: 535.313px;
  }

  .image2 {
    position: absolute;
    top: 0;
    left: 544.687px;
    width: 535.313px;
    height: 535.313px;
  }

  .image3 {
    position: absolute;
    top: 544.687px;
    left: 0;
    width: 535.313px;
    height: 535.313px;
  }

  .image4 {
    position: absolute;
    top: 544.687px;
    left: 544.687px;
    width: 535.313px;
    height: 535.313px;
  }

  .content-wrapper {
    display: flex;
    position: absolute;
    top: 438.844px;
    width: 100%;
    height: 202.312px;
    opacity: 0.8;
    align-items: center;
    background-color: #112f23;

    &--header {
      width: 100%;
    }
  }

  .border {
    border-left: 5px solid white;
    height: 90px;
    margin: 60px;
  }

  .headline {
    font-size: 68px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;
    color: #fff;
    background: transparent;
  }

  .address {
    font-size: 33px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: #fff;
    background: transparent;
  }

  .logo {
    margin-left: 50px;
  }

  &.black {
    .content-wrapper {
      background-color: black;
    }
  }

  &.green {
    .content-wrapper {
      background-color: #112f23;
    }

    .border {
      border-left: 5px solid #83bd41;
      background: #83bd41 !important;
    }
  }
}
