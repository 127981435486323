.facebook-19-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .content-wrapper {
    display: block;
    text-align: center;
    padding: 30px;
    padding-top: 100px;
    position: absolute;
    width: 500px;
    height: 100%;
    background-color: white;
    opacity: 0.8;
  }

  .headline {
    font-size: 60px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;
    background: transparent;
  }

  hr {
    width: 100px;
    height: 3px;
    position: absolute;
    background-color: #112f23;
  }

  .address {
    margin-top: 40px;
    font-size: 24px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    background: transparent;
  }

  .detail {
    margin-top: 40px;
    font-size: 24px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    background: transparent;
  }

  .logo {
    position: relative;
    margin: 0 auto !important;
    top: 55%;
    width: 40%;
  }

  &.black {
    .detail,
    .address,
    .headline {
      color: black;
    }
    hr {
      color: black;
      background: black;
    }
  }

  &.green {
    .detail,
    .address,
    .headline {
      color: #112f23;
    }
    hr {
      color: #83bd41 !important;
      background: #83bd41 !important;
    }
  }
}
