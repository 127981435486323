.instagram-2-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 960px;
  }

  .image2 {
    position: absolute;
    top: 1470px;
    left: 0;
    width: 526.744px;
    height: 450px;
  }

  .image3 {
    position: absolute;
    top: 1470px;
    left: 553.256px;
    width: 526.744px;
    height: 450px;
  }

  .headline {
    position: absolute;
    top: 1031.688px;
    left: 78.951px;
    width: 650px;

    font-size: 84px;
    font-family: 'LuxiaR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;
    color: rgb(3, 44, 143);
    background: transparent;
  }

  hr {
    position: absolute;
    top: 1176.675px;
    left: 78.951px;
    width: 161.479px;
    height: 12px;
    color: rgb(3, 44, 143);
    background: rgb(3, 44, 143);
    border: 2px solid #032c8f;
  }

  .address {
    position: absolute;
    top: 1288.649px;
    left: 78.951px;
    width: 938.498px;

    font-size: 48px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: #414042;
    background: transparent;
  }

  .sqft-icon {
    position: absolute;
    top: 1370px;
    left: 76px;

    font-size: 38.198px;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .sqft-input {
    position: absolute;
    top: 1374.431px;
    left: 144px;
    width: 211.36px;

    font-size: 38.198px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .bath-icon {
    position: absolute;
    top: 1370px;
    left: 440px;

    font-size: 38.198px;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .bath-input {
    position: absolute;
    top: 1374.431px;
    left: 515px;
    width: 70px;

    font-size: 38.198px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .bed-icon {
    position: absolute;
    top: 1370px;
    left: 590px;

    font-size: 38.198px;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .bed-input {
    position: absolute;
    top: 1374.431px;
    left: 661px;
    width: 100px;

    font-size: 38.198px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .logo {
    position: absolute;
    top: 1025px;
    right: 10px;
    width: 290.255px;
    height: auto;
  }
}
