.copy-wrapper {
  position: relative;
  padding: 1em 2em;
  padding: 1.5em 2em;
  margin-top: 10px;

  color: #3d3d3d;
  background: #eaedef;
}
.copy-wrapper .copy-message-button {
  position: absolute;
  top: 0px;
  right: 0px;

  background: none;

  &:hover {
    background: none;
  }
}
.message-result-text {
  width: 90%;
  line-height: 1.5em;
  font-family: 'Lato', 'Helvetica Neue', 'Arial', 'Helvetica', 'sans-serif';
  text-align: left;
  white-space: pre-line;
}
