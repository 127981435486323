.select-listing-btn.ui.button {
  padding: 0;
  margin: 5px 0;
  width: 100%;
}

.search-result {
  position: relative;
  line-height: 2em;
  background: #eaedef;
  font-size: 18px;
  font-weight: bold;
  padding: 1.5em 2em;
  text-align: left;
  color: #3d3d3d;
}

.search-result .exact-match {
  position: absolute;
  top: 15px;
  right: 10px;
}

.search-result:hover {
  background: #b3b8ba;
}

.desc {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
