.mac.brochure-header {
  h1,
  h2 {
    font-family: 'LuxiaR', Verdana, Tahoma;
  }
}

.brochure-header {
  h1,
  h2 {
    font-family: 'OvoR', Verdana, Tahoma;
  }
}
