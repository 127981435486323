.whistler-social-15-wrapper {
  .image1 {
    position: absolute;
    width: inherit;
    height: inherit;
  }

  .overlay-light {
    position: absolute;
    right: 0;
    width: 450px;
    height: 100%;
  }

  .content-wrapper {
    position: absolute;
    width: 480px;
    height: 80%;
    left: 50%;
    top: 100px;
    padding: 175px 65px;

    display: flex;
    flex-direction: column;
    text-align: center;
    justify-content: space-between;
    background-color: white;
    color: var(--whistler-grey);

    .testimonial {
      font-family: 'AcuminB';
      font-size: 35px;

      textarea {
        line-height: 1.5em;
      }
    }

    .client {
      font-size: 20px;
      font-family: 'AcuminR';
    }
  }
}
