.page-title {
  background-color: #233349;
  padding: 16px;
  color: white;
  font-weight: bold;
  font-size: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
}
