.facebook-17-wrapper {
  .background {
    position: fixed;
    height: 1080px;
    width: 1080px;
    z-index: 1000;
    background-color: #112f23;
  }
  .title {
    position: absolute;
    top: 188px;
    left: 0;
    width: 100%;

    font-size: 64px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 2px;
    color: white;
    background: transparent;
  }

  hr {
    position: absolute;
    top: 297.566px;
    left: 450.765px;
    width: 178px;
    height: 5px;
    color: white;
    background: white;
  }

  .content-wrapper {
    position: absolute;
    top: 420.09px;
    left: 315.026px;
    width: 449.947px;

    .content {
      font-size: 33px;
      font-family: 'OvoR', Verdana, Tahoma;
      text-align: center;
      width: 110%;
      letter-spacing: 2px;
      color: white;
    }

    .clients {
      font-size: 20px;
      margin-top: 30px;
      font-family: 'OvoR', Verdana, Tahoma;
      text-align: center;
      letter-spacing: 0;
      color: white;
    }
  }

  .logo {
    position: absolute;
    top: 855.872px;
    left: calc(50% - 90px);
  }

  &.black {
    .background {
      background-color: black !important;
    }
  }

  &.green {
    .background {
      background-color: #112f23 !important;
    }
  }
}
