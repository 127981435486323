.whistler-social-3-wrapper {
  width: 1080px;
  height: 1080px;

  .image1 {
    position: absolute;
    width: inherit;
    height: inherit;
  }

  .content-wrapper {
    position: absolute;
    width: 450px;
    height: 100%;
    padding: 80px 50px;
    background: rgba(0, 0, 0, 0.7);
    text-align: left;
    font-family: 'AcuminR', Verdana, Tahoma;
    color: white;
  }

  .headline {
    font-size: 45px;
  }

  hr {
    width: 100px;
    height: 3px;
    background: #fff;
    margin: 40px 0;
  }

  .address,
  .listingDetails {
    margin-bottom: 30px;
    font-size: 28px;
    min-height: 3em;
  }

  .logo {
    position: absolute;
    top: 820px;
    width: 250px;
    margin: auto;
    left: 25%;
  }
  .content-wrapper .editable {
    color: #fff;
  }
  .content-wrapper .editable:hover {
    color: #000;
  }
}
