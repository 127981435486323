.outlined-btn {
  padding: 10px 20px;
  border: 1px solid white;
  color: white;
  border-radius: 4px;
  text-align: center;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  display: inline-block;
}
