.facebook-10-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 80%;
  }

  .headline {
    position: absolute;
    top: 327.311px;
    left: 20px;

    font-size: 124px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 2px;
    color: white;
    font-weight: bold;
  }

  .logo {
    text-align: center;
    margin: auto;
    position: absolute;
    top: 896.892px;
    left: 40%;
    width: 250px;
  }
}
