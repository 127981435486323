.collect-basic-details {
  .welcome-header {
    color: #4a65aa !important;
    font-family: 'MontserratM';
    font-weight: bold;
  }

  .terms-link {
    color: var(--slate-grey) !important;
  }
}
