.vw-brochure-2-wrapper {
  input,
  textarea {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    color: inherit;
    font-size: 18px;
    margin-bottom: 10px;
  }
  .page-1,
  .page-2,
  .page-3,
  .page-4 {
    width: 100%;
    height: 1056px;
    margin: auto;
    background-color: #fff;
    box-sizing: border-box;
  }

  .page-1 {
    .hero-image {
      position: relative;
      height: 430px;
      background: center no-repeat url('../../../images/camera.svg');
      background-size: 64px;
    }

    .logo-div img {
      width: auto;
      height: 60px;
      display: block;
      margin: auto;
      object-fit: contain !important;
    }

    .logo-div {
      clip-path: polygon(0 0, 100% 0%, 100% 75%, 50% 100%, 0 75%);
      position: absolute;
      top: 0;
      left: 50px;
      width: 100px;
      height: 100px;
      background: white;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image-container {
      display: flex;
      height: 200px;
      margin-top: 5px;
      justify-content: space-between;

      .listing-image {
        position: relative;
        width: 100%;
        background: center no-repeat url('../../../images/camera.svg');
        background-size: 64px;
      }
      .listing-image:nth-child(1),
      .listing-image:nth-child(2) {
        padding-right: 5px;
      }
    }

    .footer {
      background-color: white;
      height: 460px;
      // about section
      .about-section {
        height: 320px;
        position: relative;
        padding: 25px;
        .address {
          color: #1d759e;
          font-weight: 400;
          font-size: 24px;
          margin-top: 0;
          margin-bottom: 18px;
          font-family: 'MontserratM';
          .input {
            color: rgb(164, 33, 33) !important;
          }
        }
        .about-content {
          border-bottom: 1.5px solid #1d759e;
          .description {
            font-size: 12px;
            word-spacing: 3px;
            line-height: 20px;
            font-family: 'MontserratR';
            margin-bottom: 20px;
            // max-height: 305px;
            overflow: hidden;
            .description-input {
              width: 100%;
              height: 150px;
              font-size: 10px;
              border: none;
              line-height: 20px;
              font-size: 14;
              text-align: 'justify';
              white-space: 'normal';
              padding: 0;
              overflow-y: hidden !important;
              padding-top: 30px;
            }
          }
          .bed-icon {
            background-color: #1d759e;
            color: white;
            padding: 8px 12px;
            float: left;
            border-radius: 2px;
            margin-right: 8px;
          }
        }

        &:hover .edit-btn-hover {
          opacity: 1;
        }
        // &:hover > div {
        //   opacity: 0.5;
        // }
      }

      // agent section
      .agent-section {
        padding-left: 25px;
        padding-right: 25px;
        padding-bottom: 25px;
        height: 140px;

        .agent-div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .left-side {
            position: relative;
            .agent-pic {
              height: 120px;
              width: auto;
            }

            .agent-item {
              display: flex;
              align-items: center;
              .agent-pic-wrapper {
                position: relative;
                &:hover .edit-btn-hover {
                  opacity: 1;
                }
              }

              .agent-pic {
                height: 100px;
                width: auto;
              }
              .agent-info {
                margin: 0 16px;
                .agent-name {
                  color: #1d759e;
                  font-size: 16px;
                  margin-top: 0;
                  margin-bottom: 6px;
                  font-family: 'MontserratM';
                }
                .agent-title,
                .agent-email,
                .agent-website {
                  margin-top: 0;
                  font-size: 14px;
                  margin-top: 4px;
                  font-family: 'MontserratR';
                }
                .agent-website {
                  margin-bottom: 0px;
                }
                .mobile-p {
                  font-size: 16px;
                  font-family: 'MontserratR';
                }
              }
            }

            // &:hover > div {
            //   opacity: 0.5;
            // }
          }
          .right-side {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;
            .agent-logo {
              height: 90px;
              width: 150px;
            }
            .qualified-text {
              margin-top: 8px;
              font-size: 12px;
              text-align: center;
              font-family: 'MontserratR';
            }
          }
        }
      }
    }
  }

  .page-2 {
    .image-container {
      display: flex;
      height: 270px;
      justify-content: space-between;

      .listing-image {
        position: relative;
        width: 100%;
        background: center no-repeat url('../../../images/camera.svg');
        background-size: 64px;
      }
      .listing-image:nth-child(1) {
        margin-right: 5px;
        margin-bottom: 5px;
      }
      .listing-image:nth-child(2) {
        margin-left: 0;
        margin-bottom: 5px;
      }
    }

    .highlight-section {
      padding: 0px;
      height: 180px;
      .high-div {
        display: flex;
        height: 100%;
        .highlight-image-container {
          position: relative;
          height: 100%;
          width: 320px;
        }
        .highlight-image {
          width: 320px;
          height: 180px;
          background: center no-repeat url('../../../images/camera.svg');
          background-size: 64px;
        }
        .h-item {
          padding-left: 5px;
          width: 100%;
          position: relative;
          .neighborhood-highlights {
            background: #1d759e;
            color: white;
            font-family: 'MontserratM';
            font-size: 20px;
            margin-top: 0;
            padding: 10px 15px;
            height: 40px;
          }
          .highlight-description {
            padding: 10px 15px;
            font-size: 12px;
            word-spacing: 2px;
            line-height: 18px;
            font-family: 'MontserratR';

            .features-list {
              display: flex;
              flex-wrap: wrap;

              .list {
                width: 50%;
              }

              .feature {
                display: flex;
                justify-content: space-between;
                padding: 0 10px;
                line-height: 1.5em;
                display: -webkit-box;
                -webkit-line-clamp: 8;
                -webkit-box-orient: vertical;
                overflow: hidden;
                margin-top: 20px !important;
                font-size: 18px;
              }
            }
          }

          &:hover .edit-btn-hover {
            opacity: 1;
          }
          // &:hover > div {
          //   opacity: 0.5;
          // }
        }
      }
    }

    .feature-section {
      padding: 0px;
      padding-top: 5px;
      height: 334px;
      position: relative;

      .high-div {
        display: flex;
        height: 100%;
        .feature-image-container {
          position: relative;
          width: 320px;
        }
        .feature-image {
          height: 100%;
          width: 320px;
          background: center no-repeat url('../../../images/camera.svg');
          background-size: 64px;
        }
        .h-item {
          padding-left: 5px;
          width: 100%;
          position: relative;
          .feature-title {
            background: #1d759e;
            color: white;
            font-family: 'MontserratM';
            font-size: 20px;
            margin-top: 0;
            padding: 10px 15px;
            height: 40px;
          }
          .feature-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 467px;

            .feature-description {
              padding: 10px 15px;
              margin: 0 16px;
              font-size: 13px;
              word-spacing: 2px;
              line-height: 18px;
              display: flex;
              flex-wrap: wrap;
              font-family: 'MontserratR';

              .list {
                list-style: disc;
                padding: 10px;
                padding-left: 0px;
                width: 50%;
              }
            }

            .break-line {
              background-color: #1d759e;
              height: 1.5px;
              width: 100%;
            }

            .footer-logo-container {
              padding: 0 15px 15px 0;
              display: flex;
              justify-content: flex-end;
              margin-top: 30px !important;

              .logo-footer {
                width: 200px;
                height: 60px;
                object-fit: contain;
                margin-top: 20px;
              }
            }
            &:hover .edit-btn-hover {
              opacity: 1;
            }
            // &:hover > div {
            //   opacity: 0.5;
            // }
          }
        }
      }
    }
  }

  .page-3 {
    .banner-image {
      position: relative;
      width: inherit;
      height: 550px;

      .feature-image {
        height: 100%;
        width: 100%;
        background: center no-repeat url('../../../images/camera.svg');
        background-size: 64px;
      }
    }

    .grid-images-container {
      width: inherit;
      height: 500px;
      margin-top: 5px;

      .grid-row-1,
      .grid-row-2 {
        display: flex;
        height: 245px;
        width: 100%;

        .grid-col-1,
        .grid-col-2 {
          position: relative;
          height: 100%;

          .grid-image {
            width: 100%;
            height: 100%;
            background: center no-repeat url('../../../images/camera.svg');
            background-size: 64px;
          }
        }

        .grid-col-1 {
          margin-right: 5px;
          width: 100%;
        }
        .grid-col-2 {
          margin-left: 0;
          width: 100%;
        }
      }

      .grid-row-2 {
        margin-top: 5px;
      }
    }
  }

  .page-4 {
    .top-header {
      background: #1d76a0;
      padding: 20px 24px;
      height: 60px;
      display: flex;
      align-items: center;

      .address {
        font-size: 18px;
        color: white;
      }
    }
    .main-area {
      height: 830px;
      padding: 48px 24px;

      .floorplan {
        width: 100%;
        position: relative;

        .floorplan-img {
          position: relative;
          .image-container {
            .scale-back {
              position: absolute;
              top: 44%;
              left: 40.5%;
              transform: translate(-49%, -33%);
              -ms-transform: translate(-49%, -33%);
            }
          }
          .upload-floorplan-pdf {
            position: absolute;
            top: 57%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            opacity: 0;

            &.lg {
              top: 63%;
            }
          }
        }

        .floorplan-img:hover {
          .upload-floorplan-pdf,
          .image-container .upload-button {
            opacity: 1;
          }

          .listing-photo {
            opacity: 0.5;
          }
        }
      }
    }
    .footer {
      height: 150px;
      padding: 0 20px 54px;

      .grid-row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100%;

        .grid-col-1 {
          width: 100%;
          height: 60px;
          .logo-container {
            width: 100%;
            height: 60px;
            .logo {
              width: 256px;
              height: 60px;
              object-fit: contain;
            }
          }
        }
        .grid-col-2 {
          display: flex;
          justify-content: center;
          align-items: center;

          height: 100%;
          width: 300px;
          background-color: #1d76a0;
          color: white;

          .content {
            padding: 0 16px;
            width: 100%;
            .download-app {
              font-style: italic;
              font-size: 12px;
            }
            .website {
              margin-top: 2px;
              font-size: 12px;

              .url {
                font-style: italic;
                margin-left: -3px;
                color: white;

                input {
                  font-style: italic;

                  &:hover {
                    color: black;
                  }

                  &::placeholder {
                    color: white;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
