.mac-template-3 {
  &.page-1,
  &.page-2 {
    width: 610px;
    height: 792px;
    padding: 36px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
  }

  .mac-listing-info {
    display: block;

    .listing-desc-container {
      width: 494px;
      margin: auto;
      // height: 152px;

      .description {
        line-height: 14px;
      }
    }

    .photo-layout-1.large {
      margin-bottom: 9px !important;
    }

    .photo-layout-2 {
      margin-bottom: 20px;
    }

    .agent-branding {
      margin-top: 20px;
    }

    .listing-info {
      .logo {
        top: -5px;
      }
      .listing-intro {
        top: 5px;
        position: relative;
      }
    }
  }
}
