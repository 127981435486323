.boulevard-template-1-wrapper {
  width: 1050px;
  height: 1423px;
  background-color: white;

  .header {
    height: 50px;
    width: 100%;
  }

  .footer {
    height: 89px;
    width: 100%;
    bottom: 0;
    position: absolute;
    width: 1050px;
  }

  .header-image-container {
    position: relative;
    width: 1050px;
    height: 589px;

    .image1 {
      width: 1050px;
      height: 589px;

      background: center no-repeat url('../../../images/camera.svg');
      background-size: 64px;
    }
  }

  .sub-main {
    display: flex;
    width: 1050px;
    text-align: center;
    flex-direction: column;

    .card-div {
      background-color: white;
      padding: 0 10px 0 10px;
      height: 120px;

      .inputs-container {
        border-left: 4px solid white;
        margin-left: 40px;
        margin-right: 40px;
        padding-left: 60px;

        .address {
          font-size: 40px;
          text-transform: uppercase !important;
          font-weight: bold;
          margin-top: 0;
          margin-bottom: 20px;

          textarea {
            text-transform: uppercase !important;
            font-weight: bold;
            color: inherit;
          }
        }

        .listingDetails {
          font-size: 27px;
          margin-top: 0;
          font-weight: 300;
          margin-bottom: 10px;

          textarea {
            font-size: 27px;
            font-weight: 300;
            color: black;
          }
        }
      }
    }
  }

  .grid-image-container {
    display: flex;
    margin: 5px 0;
    height: 333px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .grid {
      position: relative;
      width: 524px;
      height: 333px;

      &.right {
        margin-left: 3px;
      }
      &.left {
        margin-right: 3px;
      }

      .grid-image {
        width: 100%;
        height: 100%;

        background: center no-repeat url('../../../images/camera.svg');
        background-size: 64px;
      }
    }
  }

  .agent-container {
    width: 1050px !important;
    padding: 0 20px 0 20px;
    z-index: 1000;
    display: flex;
    background-color: transparent;

    .agent-photo {
      width: 226px;
      height: 286px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      margin-right: 18px;
      background-color: transparent;
      position: relative;

      .empty-placeholder {
        background-color: white;
        background-image: url('../../../images/person.svg');
        background-size: 150px;
      }
    }

    .agent-info {
      width: 600px;
      height: 280px;
      margin-top: 40px;
      justify-content: flex-end;
      flex-direction: column;

      .agent-name {
        font-size: 35px;
      }

      .agent-phone {
        font-size: 29px;
        line-height: 43px;
        // margin-left: -270px;
        text-align: start;
      }

      .agent-title {
        font-size: 22px;
        line-height: 43px;
      }
      .agent-contact {
        font-size: 29px;
        line-height: 43px;
        margin-top: 0px;
      }

      .agent-website {
        color: white;
        background-color: transparent;
        margin-top: 40px;
        font-weight: bold;
      }
    }
    // .optional-logo {
    //   position: relative;
    //   width: 200px;
    //   height: 100px;
    //   background-color: white;
    // }

    // .optional-logo-background {
    //   background-color: #80808070;
    //   border: 1px dotted black;
    // }
  }
}
