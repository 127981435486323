.billing-information {
  .user-details {
    margin-top: 36px;
    .user-name {
      font-size: 22px;
      font-weight: bold;
      color: #233349;
      margin-bottom: 12px;
    }
  }

  .action-btn-container {
    margin-top: 36px;
    width: 160px;
  }

  .title {
    font-size: 16px;
    font-weight: bold;
    color: black;
  }

  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
  }

  .credit-card {
    margin-top: 30px;
    .credit-card-section {
      margin: 36px 0;
    }
  }
}
