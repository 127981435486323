.whistler-social-10-wrapper {
  .content-wrapper {
    position: relative;
    top: 560px;
    width: 100%;
    height: 400px;
    display: flex;
    align-items: center;

    .border {
      border-left: 4px solid var(--whistler-red);
      margin: 0 102px;
      height: 70%;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 55%;
      width: 75%;

      .headline {
        font-size: 80px;
        font-family: 'AcuminB', Verdana, Tahoma;
        margin-bottom: 10px;
      }

      .address {
        font-size: 34px;
        font-family: 'AcuminR';
        color: var(--whistler-grey);
      }

      .agent-details {
        font-size: 27px;
        font-family: 'AcuminR';
        color: var(--whistler-grey);
      }
    }
  }
}
