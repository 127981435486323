.payment-onboarding {
  .left-sidebar {
    .payment-header {
      text-transform: uppercase;
      color: #4a65aa !important;
      font-family: 'MontserratM';
      font-weight: bold;
    }
    .form-container,
    .subscription-details-container {
      margin-top: 36px;
    }
    .form-field {
      margin-bottom: 16px 0;
      .input-container {
        position: relative;
        border: 1px solid #ccc;
        border-radius: 4px;
        padding: 8px 10px;

        .card-icon {
          position: absolute;
          top: 0;
          right: 0;
          padding: 8px;
          color: #aaa;
        }
      }
    }
  }
  .right-sidebar {
    color: white;
    background-color: var(--dark-blue);
    .subscription-header {
      text-transform: uppercase;
      font-family: 'MontserratM';
      font-weight: bold;
      color: white;
    }
  }
}
