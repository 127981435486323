.mac-horizontal-window-poster {
  font-size: 10px;
  font-family: 'UbuntuL', Verdana, Tahoma;

  .logo-fixed {
    background-color: #0054a5;
    width: 130px;
    height: 125px;
    position: absolute;
    top: 0;
    left: 60px;
    z-index: 1;
    padding-top: 20px;
  }

  .info-photo-layout {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .photo-layout {
    display: inline-flex;
    justify-content: space-between;

    .image-container {
      margin: 4px;
    }
  }

  .listing-intro {
    float: right;
    font-size: 25px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: right;
    margin-top: 0px;
    color: #0054a5;

    h1,
    h2 {
      font-size: 34px;
      line-height: 33.6px;
      max-width: 420px;
      max-height: 38px;
      overflow: hidden;
    }
    h2 {
      margin-bottom: 10px;
    }
  }

  .listing-desc {
    width: 715px; //660px
    margin: auto;

    .address {
      font-size: 17px;
      font-family: 'UbuntuM', Verdana, Tahoma;
      color: #0054a5;
      margin-bottom: 10px;
      // line-height: 1em;
      margin-left: 25px;
    }

    .content-layout {
      display: inline-flex;
      justify-content: space-between;
      width: inherit;
      height: 95px;
      margin-left: 25px;
    }

    .description {
      font-family: 'UbuntuL', Verdana, Tahoma;
      font-size: 13px;
      flex: 5;
      line-height: 10px;
      padding-right: 20px;
      color: black;
      display: -webkit-box;
      -webkit-line-clamp: 6;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .details {
      b {
        font-weight: bold;
      }
      flex: 1;
      font-size: 10px;
      line-height: 2em;
      font-family: 'UbuntuM', Verdana, Tahoma;
      color: #3d464d;
      display: inline-block;
    }

    .edit-btn-hover {
      top: 63%;
    }

    &:hover .edit-btn-hover {
      opacity: 1;
    }

    &:hover > div,
    &:hover > span {
      opacity: 0.5;
    }
  }

  .two-agents {
    margin-left: 28px;
    display: flex;

    .agent-branding.no-logo:first-of-type {
      margin-right: 10px;
      min-width: 49%;
    }

    .agent-contact {
      font-size: 9px;
    }
    .agent-branding.no-logo .agent-photo {
      min-width: 80px;
      max-width: 80px;
    }
  }

  .agent-branding.no-logo {
    width: 660px;
    margin: auto;

    .agent-photo {
      min-width: 85px;
      max-width: 85px;
      margin-top: 20px;
      height: 110px;
    }

    .agent-info {
      width: 100%;
      justify-content: center;
      .agent-name {
        border-top: grey 1px solid;
        padding-top: 8px;
      }
      .agent-name,
      .agent-phone {
        font-size: 13.6px;
      }
      .agent-title,
      .agent-contact {
        font-size: 9.5px;
        color: black;
      }
    }

    .agent-phone {
      font-size: 16px;
    }
  }

  .legal {
    position: relative;
    left: 123px;
    margin-top: 7px;
    font-size: 6px;
    font-family: 'UbuntuR', Verdana, Tahoma;
  }
}
.mac-template-7 {
  .legal {
    position: absolute;
    left: 0;
  }
  .listing-intro h2 input {
    font-size: 22px !important;
  }

  .listing-desc {
    .description {
      min-width: 600px !important;
    }
  }
}

.mac-template-8 {
  .legal {
    margin-left: 28px;
    left: 0;
  }

  .listing-desc {
    .description {
      min-width: 555px !important;
    }
  }
}
