// Base styles that are applied for all Whistler Social Templates

@font-face {
  font-family: 'MontserratL';
  src: url(/fonts/Montserrat-Light.otf) format('truetype');
}

@font-face {
  font-family: 'MontserratR';
  src: url(/fonts/Montserrat-Regular.otf) format('truetype');
}

@font-face {
  font-family: 'MontserratSB';
  src: url(/fonts/Montserrat-SemiBold.otf) format('truetype');
}

.vw-base-social-template {
  width: 1200px;
  height: 628px;

  // Whistler branded colors
  --vw-red: #d52027;
  --vw-grey: #9d9d9d;
  --vw-black: #000000;
  --vw-blue: rgba(29, 118, 160, 0.85);
  --vw-white: #fff;

  // Editable image for template background
  .template-background-image {
    position: absolute;
    width: inherit;
    height: inherit;
  }

  // Transparent black - font white
  .overlay-dark {
    width: 300px;
    background: rgba(29, 118, 160, 0.85);
    color: rgba(255, 255, 255, 0.8);
    height: 100%;
    padding: 50px 40px;
    position: relative;
  }

  // Transparent white - font black
  .overlay-light {
    width: 300px;
    height: 100%;
    padding: 50px 40px;
    position: relative;
    background: rgba(255, 255, 255, 0.8);
    color: var(--vw-blue);

    .headline {
      color: var(--vw-blue);
    }
  }

  textarea {
    resize: none;
    color: #515153;
  }
}
