.mac-facebook-4-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgb(3, 44, 134);
  }

  .headline {
    position: absolute;
    top: 327.311px;
    left: 20px;
    width: 1040px;

    font-size: 84px;
    font-family: 'LuxiaR', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 2px;
    color: #fff;
    background: transparent;
  }

  hr {
    position: absolute;
    top: 492.57px;
    left: 493.071px;
    width: 93.859px;
    height: 12px;
    color: #fff;
    background: #fff;
  }

  .address {
    position: absolute;
    top: 602.009px;
    left: 20px;
    width: 1040px;

    font-size: 43px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 0;
    color: #fff;
    background: transparent;
  }

  .detail {
    position: absolute;
    top: 664.451px;
    left: 20px;
    width: 1040px;

    font-size: 30px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 0;
    color: #fff;
    background: transparent;
  }

  .logo {
    position: absolute;
    top: 896.892px;
    left: 449.493px;
    width: 189.497px;
    height: auto;
  }
}
