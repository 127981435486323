.whistler-brochure-3-wrapper,
.whistler-brochure-4-wrapper,
.whistler-brochure-5-wrapper,
.whistler-brochure-6-wrapper {
  .page-1,
  .page-2,
  .page-3,
  .page-4 {
    width: 100%;
    height: 1056px;
    background-color: white;

    .hero-image {
      position: relative;
      height: 590px;
      background: center no-repeat url('../../../images/camera.svg');
      background-size: 64px;
    }

    .address {
      font-family: 'AcuminR';
      font-size: 20px;
      padding-left: 0;
      font-weight: bold;
    }
  }

  .page-1 {
    .content-wrapper {
      height: 120px;
      display: flex;
      justify-content: center;
      align-items: center;

      .description {
        font-family: 'AcuminR';
        font-size: 11px;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.2em;
      }
    }

    .footer {
      height: 82px;
      display: flex;

      .logo {
        position: relative;
        width: 250px;
        margin: auto;
      }
    }
  }

  .page-2 {
    .hero-image {
      position: relative;
      height: 300px;
    }

    .content-wrapper {
      height: 260px;
      padding: 30px 70px;
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .description {
        font-family: 'AcuminR';
        font-size: 11px;
        padding: 0;
        display: -webkit-box;
        // -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.5em;
      }

      &:hover .edit-btn-hover {
        opacity: 1;
      }

      &:hover > div {
        opacity: 0.5;
      }
    }

    .image-container {
      display: flex;
      height: 185px;
      justify-content: space-between;

      .listing-image {
        position: relative;
        width: 100%;
        background: center no-repeat url('../../../images/camera.svg');
        background-size: 64px;
      }
    }

    .footer {
      height: 47px;
    }
  }

  .page-3 {
    .image-container {
      height: 300px;
      display: flex;
      justify-content: space-between;

      .image-left {
        position: relative;
        width: 480.5px;
        background: center no-repeat url('../../../images/camera.svg');
        background-size: 64px;
      }

      .two-image-container {
        display: flex;
        flex-direction: column;
        width: 330px;
        justify-content: space-between;

        .image-right {
          position: relative;
          height: 148px;
          background: center no-repeat url('../../../images/camera.svg');
          background-size: 64px;
        }
      }
    }

    .border {
      border-left-width: 2px;
      border-left-style: solid;
      margin: 0 60px 0 60px;
      height: 70%;
    }

    .features-container {
      height: 260px;
      margin: 0 20px 0 20px;
      font-family: 'AcuminR';
      font-size: 12px;
      display: flex;
      align-items: center;
      position: relative;

      .features-list {
        width: 450px;
        height: 100px;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;

        .feature {
          display: flex;
          justify-content: space-between;
          padding: 0 10px;
          line-height: 1.6em;
          & > span {
            width: 143px;
            margin-left: 6px;
          }
        }
      }

      &:hover .edit-btn-hover {
        opacity: 1;
      }

      &:hover > div {
        opacity: 0.5;
      }
    }

    .image-container-bottom {
      height: 185px;
      display: flex;
      justify-content: space-between;

      .listing-image {
        position: relative;
        width: 100%;
        background: center no-repeat url('../../../images/camera.svg');
        background-size: 64px;
      }
    }

    .footer {
      height: 47px;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .logo {
        margin-right: 30px;
        width: 90px;
      }
    }
  }

  .page-4 {
    hr {
      margin: 30px;
      border-top-width: 1px;
      border-top-style: solid;
    }

    .wrc.agent-branding {
      margin: 10px 30px 0;
      .agent-info {
        font-family: 'AcuminR';
      }
    }
  }
}

.whistler-brochure-3-wrapper,
.whistler-brochure-5-wrapper {
  .page-3 .border {
    border-color: black;
  }

  .page-4 {
    hr {
      border-color: black;
    }
  }

  .footer {
    background-color: black;
  }

  .agent-banner-container {
    position: relative;
    width: 540px;
    height: 110px;
    margin: 20px auto 0;

    &:hover .edit-btn-hover {
      opacity: 1;
    }

    &:hover > div {
      opacity: 0.5;
    }

    .empty-placeholder {
      background: center no-repeat url('../../../images/person.svg');
      background-size: 64px;
      outline: 0;
    }

    .background-image {
      margin: 20px auto 0;
    }
  }
}

.whistler-brochure-4-wrapper,
.whistler-brochure-6-wrapper {
  .page-1 .content-wrapper .address,
  .page-2 .content-wrapper .address {
    color: var(--whistler-red);
  }

  .page-3 .border {
    border-color: var(--whistler-red);
  }

  .page-4 {
    hr {
      border-color: var(--whistler-red);
    }

    .wrc.agent-branding {
      .agent-name,
      .agent-contact > span {
        color: var(--whistler-red);
      }
    }
  }

  .footer {
    background-color: var(--whistler-red);
  }
}

.whistler-brochure-3-wrapper,
.whistler-brochure-4-wrapper {
  .page-4 {
    .main-area {
      width: 100%;
      height: 850px;
      .floorplan {
        width: 100%;
        height: 850px;
        position: relative;

        .floorplan-img {
          position: relative;
          .image-container {
            .scale-back {
              position: absolute;
              top: 44%;
              left: 34%;
              transform: translate(-49%, -33%);
              -ms-transform: translate(-49%, -33%);
            }
          }
          .upload-floorplan-pdf {
            position: absolute;
            top: 57%;
            left: 50%;
            transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            opacity: 0;

            &.lg {
              top: 63%;
            }
          }
        }

        .floorplan-img:hover {
          .upload-floorplan-pdf,
          .image-container .upload-button {
            opacity: 1;
          }

          .listing-photo {
            opacity: 0.5;
          }
        }
      }
    }
  }
}
