.view-card-container {
  display: inline-block;
  border: 1px solid black;
  border-radius: 5px;
  .card-number-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;

    .card-number {
      border-left: 2px solid #ccc;
      padding-right: 72px;
      margin: 8px;

      .card-img {
        padding: 0 16px;
      }
    }

    .default-card {
      color: green;
      border-color: green;
      padding: 4px 8px;
      text-transform: uppercase;
      font-size: 10px;
      background-color: greenyellow;
      font-weight: bold;
    }
    .make-default-card {
      color: gray;
      border-color: gray;
      padding: 4px 8px;
      text-transform: uppercase;
      font-size: 10px;
      background-color: gainsboro;
      font-weight: bold;
      cursor: pointer;
    }
    .update-btn {
      color: #233349;
      padding: 8px;
      font-size: 12px;
      cursor: pointer;
      font-weight: bold;
    }
    .remove-btn {
      color: tomato;
      padding: 8px;
      font-size: 12px;
      cursor: pointer;
      font-weight: bold;
    }
  }

  .spacer {
    border-bottom: 1px solid black;
  }

  .card-expiry {
    margin: 6px;
    padding: 0 8px;
  }
}
