.whistler-social-9-wrapper {
  .image1 {
    position: relative;
    width: inherit;
    height: 700px;
  }

  .details-container {
    background-color: white;
    height: 380px;
    display: flex;
    align-items: center;
    padding: 0 60px;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      height: 50%;
      width: 100%;
      text-align: center;

      .headline {
        font-size: 75px;
        font-family: 'AcuminB', Verdana, Tahoma;
        color: var(--whistler-red);
      }

      .address {
        font-size: 35px;
        font-family: 'AcuminR';
        color: var(--whistler-grey);
      }
    }
  }
}
