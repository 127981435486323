.account-settings {
  .user-details {
    margin-top: 36px;
    .user-name {
      font-size: 22px;
      font-weight: bold;
      color: #233349;
      margin-bottom: 12px;
    }
    .company-name {
      font-size: 16px;
      font-weight: bold;
      color: black;
    }

    .user-contact {
      font-size: 14px;
      font-weight: 300;
      margin: 16px 0;

      p {
        line-height: 20px;
      }
    }
  }

  .action-btn-container {
    margin-top: 36px;
  }
}
