.instagram-4-wrapper {
  padding: 36px;
  padding-top: 0;

  .headline-wrapper {
    height: 188.301px;
    background-color: rgb(239,234,231);
    opacity: 1;
    padding: 30px;
    align-items: center;
    display: flex;
  }

  .image1 {
    position: absolute;
    top: 221px;
    width: 1008px;
    height: 669.151px;
  }

  .image2 {
    position: absolute;
    top: 922.849px;
    width: 1008px;
    height: 669.151px;
  }

  .headline {
    width: 100%;
    font-size: 84px;
    font-family: 'LuxiaR', Verdana, Tahoma;
    letter-spacing: 2px;
    color: rgb(3, 44, 143);
    background: transparent;
  }

  .address {
    position: absolute;
    top: 1649px;
    left: 71.758px;
    width: 700px;
    height: 100px;

    font-size: 52px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(3, 44, 143);
    background: transparent;
  }

  .sqft-icon {
    position: absolute;
    top: 1805px;
    left: 72.255px;

    font-size: 38.198px;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .sqft-input {
    position: absolute;
    top: 1810.635px;
    left: 139.035px;
    width: 211.36px;

    font-size: 38.198px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .bath-icon {
    position: absolute;
    top: 1805px;
    left: 423px;

    font-size: 38.198px;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .bath-input {
    position: absolute;
    top: 1810.635px;
    left: 496px;
    width: 70px;

    font-size: 38.198px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .bed-icon {
    position: absolute;
    top: 1805px;
    left: 573px;

    font-size: 38.198px;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .bed-input {
    position: absolute;
    top: 1810.635px;
    left: 648px;
    width: 100px;

    font-size: 38.198px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .logo {
    position: absolute;
    bottom: 80px;
    right: -20px;
    width: 285px;
    height: auto;
  }
}
