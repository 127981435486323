.mac-facebook-18-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    right: 400px;
    width: calc(100% - 390px);
    height: 100%;
  }

  .content-wrapper {
    display: block;
    text-align: center;
    padding: 30px;
    padding-top: 100px;
    position: absolute;
    width: 400px;
    height: 100%;
    right: 0;
    background-color: #0d1834;
  }

  .award-message {
    font-size: 60px !important;
    font-weight: lighter;
    color: white;
    position: relative;
    top: 30%;
    font-family: 'LuxiaB', Verdana, Tahoma;
  }

  .top-logo {
    position: absolute;
    top: 50px;
    left: -10px;
  }

  .bottom-logo {
    position: absolute;
    bottom: 50px;
  }
}
