.whistler-social-2-wrapper {
  .image1 {
    position: absolute;
    width: inherit;
    height: inherit;
  }

  .content-wrapper {
    position: absolute;
    width: 680px;
    height: 680px;
    margin: 200px;
  }

  .headline {
    width: 90%;
    margin: auto;
    margin-top: 20%;
    font-size: 100px;
    font-family: 'AcuminR', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 2px;
    color: var(--whistler-red);
    background: transparent;
  }

  hr {
    width: 50%;
    height: 3px;
    background-color: var(--whistler-red);
    margin: 40px auto 60px;
  }

  .address {
    width: 90%;
    margin: auto;
    font-size: 40px;
    font-family: 'AcuminR', Verdana, Tahoma;
    text-align: center;
    letter-spacing: 0;
    line-height: 1.3em;
    background: transparent;
  }
}
