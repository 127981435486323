.whistler-brochure-7-wrapper {
  max-height: 3270px !important;

  input,
  span,
  div,
  p {
    font-family: 'AcuminR' !important;
  }

  .page-1 {
    font-family: 'AcuminR';
    font-size: 12px;
    width: 1056px; //half 528
    height: 1635px !important; //half 817.5 //1635
    background-color: white;
    .uppercase {
      text-transform: uppercase !important;
    }
  }

  .legal {
    margin: 15px 0px;
    font-size: 6px;
    font-family: 'AcuminR';
  }

  .page-2 {
    font-family: 'AcuminR';
    font-size: 12px;
    width: 1056px; //half 528
    height: 1635px !important; //half 817.5px
    background-color: white;
    .uppercase {
      text-transform: uppercase !important;
    }
  }

  .half-page {
    height: 817.5px !important;
    width: 1056px;

    &--top-1 {
      height: 815.5px !important;
    }
    &--bottom-1 {
      height: 815.5px !important;
    }

    &--top-2 {
      height: 815.5px !important;
    }
    &--bottom-2 {
      height: 815.5px !important;
    }
  }

  .rotate-180 {
    transform: rotate(180deg);

    .ui.button.primary {
      transform: rotate(180deg);
    }
  }

  .hero-image {
    position: relative;
    height: 663.5px;
    background: center no-repeat url('../../../images/camera.svg');
    background-size: 64px;

    &-logo {
      z-index: 1000;
      position: absolute;
      bottom: 180px;
      left: 30px;

      img {
        height: 90px;
        width: 100px;
      }
    }
  }

  .footer-wrapper {
    padding: 30px;
    height: 154px;

    .logo {
      position: relative;
      object-fit: contain !important;
    }

    .left {
      width: 332px;
      height: 120px;

      img {
          height: 70px;
          position: relative;
          object-fit: contain !important;
        }
    }

    .center {
      width: 392px;
      border-left: black solid 1px;
      border-right: black solid 1px;

      .address {
        margin: 10px 10px 0 80px !important;
      }
    }

    .right {
      width: 332px;
      height: 130px;

      img {
          height: 70px;
          position: relative;
          object-fit: contain !important;
        }
    }
  }

  .address {
    font-family: 'AcuminR';
    line-height: 20px;
    font-size: 12px;
    margin: 10px 10px 0 30px;
    display: block;

    p {
      width: 100%;
    }

    input {
      width: 100%;
      resize: horizontal;
    }

    .inline {
      display: inline-flex;

      input {
        margin: 0;
        padding: 0;
      }
    }
  }

  .bottom-right {
    height: 815.5px !important;
    width: 456px;
  }

  .page-one-image-container {
    height: 291.5px;
    width: 600px;
  }

  .right-top {
    width: 607px;
    height: 512px;
  }

  .left-top {
    width: 450px;
    height: 512px;
    position: relative;
    & .image-content {
      height: 105%;
    }
  }

  .right-bottom {
    height: 303.5px;
    width: 607px;
    position: relative;
  }

  .left-bottom {
    width: 450px;
    height: 303.5px;
    position: relative;
  }

  .content-container {
    max-height: 516px !important;
    width: 528px;
    margin: auto;
    padding: 25px 19.2px 0 19.2px;

    &--wrapper {
      hr {
        color: #9f9fa1;
      }

      & .section-one {
        height: 192px;
      }

      & .section-two {
        padding-top: 10px;
        height: 119.04px;
      }

      & .section-three {
        height: 78.72px;
        padding: 15px 0px;
        padding-right: 25px;
        width: 490px;
        display: inline-flex;
        justify-content: space-between;
        div {
          flex: 1;
          img {
            height: 50px;
            position: relative;
            object-fit: contain !important;
          }
        }
        div:nth-child(2) {
          padding-right: 15px;
        }
      }

      & .section-four {
        p {
          color: #9f9fa1;
        }
      }
    }

    .desc-wrapper {
      .description {
        font-family: 'AcuminR';
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 20;
        -webkit-box-orient: vertical;
        line-height: 1.2em;
      }
    }

    hr {
      margin: 5px 0;
      border-top-width: 1px;
      border-top-style: solid;
    }
  }

  .agent-optional-logo-container {
    height: 115.2px;
    width: 460.8px;

    position: relative;
    margin: 20px auto 0;

    &:hover .edit-btn-hover {
      opacity: 1;
    }

    &:hover > div {
      opacity: 0.5;
    }

    .empty-placeholder {
      background: center no-repeat url('../../../images/person.svg');
      background-size: 64px;
      outline: 0;
    }

    .background-image {
      margin: 20px auto 0;
    }

    .optional-logo {
      position: relative;
      // bottom: 0;
      z-index: 1000;
      height: inherit;
      width: inherit;
      object-fit: contain !important;
    }
  }

  .background-optional-logo {
    background-color: #f2f2f2;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.15);
  }

  .content-wrapper {
    width: 460.8px;
    height: 500px;
    margin: auto;
    padding: 50px 0;
    font-size: 12px;

    .desc-wrapper {
      height: 230.4px;
      .description {
        font-family: 'AcuminR';
        font-size: 12px;
        display: -webkit-box;
        -webkit-line-clamp: 20;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.2em;
      }
    }
  }

  .details {
    line-height: 20px;
    display: inline-flex;
    justify-content: flex-start !important;
    margin: none !important;

    input[type='text'] {
      min-width: 10px !important;
      padding: 0;
    }
  }

  .inline-flex {
    display: inline-flex;
  }

  .around {
    width: 100%;
    justify-content: space-around;
  }

  .between {
    width: 100%;
    justify-content: space-between;
  }

  .flex-start {
    justify-content: flex-start;
  }

  .listing-image {
    position: relative;
    height: inherit;
    width: inherit;
    background: center no-repeat url('../../../images/camera.svg');
    background-size: 64px;
  }
}

.social {
  font-size: 14px;
  .social-list {
    display: inline-flex;
    height: fit-content;
    width: 40%;

    p {
      margin-right: 10px;
    }

    img {
      margin: 1.5px;
      margin-right: 10px;
    }
  }
}
