.vw-brochure-1-wrapper {
  input,
  textarea {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;
    color: inherit;
  }
  .page-1,
  .page-2 {
    // width: 612px;
    width: 816px;
    // height: 950px;
    height: 1056px;
    margin: auto;
    padding: 36px;
    background-color: #fff;
    box-sizing: border-box;
  }

  .page-1 {
    .hero-image {
      position: relative;
      // height: 278px;
      height: 480px;
      background: center no-repeat url('../../../images/camera.svg');
      background-size: 64px;
    }

    .logo-div img {
      width: auto;
      height: 60px;
      display: block;
      margin: auto;
      object-fit: contain !important;
    }

    .logo-div {
      clip-path: polygon(0 0, 100% 0%, 100% 75%, 50% 100%, 0 75%);
      position: absolute;
      top: 0;
      left: 50px;
      width: 100px;
      height: 100px;
      background: white;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .image-container {
      display: flex;
      // height: 118px;
      height: 270px;
      width: 100%;
      margin-top: 4px;
      justify-content: space-between;

      .listing-image {
        position: relative;
        width: 100%;
        background: center no-repeat url('../../../images/camera.svg');
        background-size: 64px;
      }
      .listing-image:nth-child(1),
      .listing-image:nth-child(2) {
        padding-right: 4px;
      }
    }

    .footer {
      background-color: white;
      height: 392px;
      // about section
      .about-section {
        height: 256px;
        // height: 226px !important;
        position: relative;
        padding: 30px;
        // padding: 30px 30px 0 30px;
        .address {
          color: #1d759e;
          font-weight: 400;
          font-size: 24px;
          margin-top: 0;
          margin-bottom: 18px;
          font-family: 'MontserratM';
          .input {
            color: rgb(164, 33, 33) !important;
          }
        }
        .about-content {
          border-bottom: 1.5px solid #1d759e;
          // height: 150px;
          .description {
            font-size: 12px;
            word-spacing: 3px;
            line-height: 20px;
            font-family: 'MontserratR';
            margin-bottom: 5px;
            // max-height: 155px;
            height: 153px;
            overflow: hidden;
            .description-input {
              width: 100%;
              height: 125px;
              border: none;
              line-height: 20px;
              font-size: 14;
              text-align: 'justify';
              white-space: 'normal';
              overflow-y: hidden !important;
              margin-top: 12px;
            }
          }
          .bed-icon {
            background-color: #1d759e;
            color: white;
            padding: 8px 12px;
            float: left;
            border-radius: 2px;
            margin-right: 8px;
            margin-bottom: -10px;
          }
        }

        &:hover .edit-btn-hover {
          opacity: 1;
        }
        // &:hover > div {
        //   opacity: 0.5;
        // }
      }

      // agent section
      .agent-section {
        padding-left: 30px;
        padding-right: 30px;
        padding-bottom: 30px;
        height: 140px;

        .agent-div {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 100px;

          .left-side {
            position: relative;
            .agent-pic {
              height: 120px;
              width: auto;
            }

            .agent-item {
              display: flex;
              align-items: center;
              .agent-pic-wrapper {
                position: relative;
                &:hover .edit-btn-hover {
                  opacity: 1;
                }
              }

              .agent-pic {
                height: 100px;
                width: auto;
              }
              .agent-info {
                margin: 0 16px;
                .agent-name {
                  color: #1d759e;
                  font-size: 16px;
                  margin-top: 0;
                  margin-bottom: 6px;
                  font-family: 'MontserratM';
                }
                .agent-title,
                .agent-email,
                .agent-website {
                  margin-top: 0;
                  font-size: 14px;
                  margin-top: 4px;
                  font-family: 'MontserratM';
                }
                .agent-website {
                  margin-bottom: 0px;
                }
                .mobile-p {
                  font-size: 16px;
                  font-family: 'MontserratR';
                }
              }
            }
            &:hover .edit-btn-hover {
              opacity: 1;
            }
            // &:hover > div {
            //   opacity: 0.5;
            // }
          }
          .right-side {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: relative;

            .agent-logo {
              height: 90px;
              width: 150px;
            }
            .qualified-text {
              margin-top: 8px;
              font-size: 12px;
              text-align: center;
              font-family: 'MontserratR';
            }
          }
        }
      }
    }
  }

  .page-2 {
    padding: 0;
    .image-container {
      display: flex;
      height: 176px;
      justify-content: space-between;
      width: 816px;

      .listing-image {
        position: relative;
        width: 100%;
        background: center no-repeat url('../../../images/camera.svg');
        background-size: 64px;
      }
      .listing-image:nth-child(1) {
        margin-right: 2px;
        margin-bottom: 4px;
      }
      .listing-image:nth-child(2) {
        margin-left: 2px;
        margin-bottom: 4px;
      }
    }

    .highlight-section {
      padding: 0px;
      height: 200px;
      .high-div {
        display: flex;
        .highlight-image-container {
          position: relative;
          height: 100%;
          width: 180px;
        }
        .highlight-image {
          height: 240px;
          width: 180px;
          background: center no-repeat url('../../../images/camera.svg');
          background-size: 64px;
        }
        .h-item {
          padding-left: 6px;
          width: 100%;
          position: relative;
          .neighborhood-highlights {
            background: #1d759e;
            color: white;
            font-family: 'MontserratM';
            font-size: 20px;
            margin-top: 0;
            padding: 10px 15px;
            height: 40px;
          }
          .highlight-description {
            padding: 10px 15px;
            font-size: 12px;
            word-spacing: 2px;
            line-height: 18px;
            font-family: 'MontserratR';

            .features-list {
              display: flex;
              flex-wrap: wrap;

              .list {
                width: 50%;
              }

              .feature {
                display: flex;
                justify-content: space-between;
                padding: 0 10px;
                line-height: 1.5em;
                display: -webkit-box;
                -webkit-line-clamp: 8;
                -webkit-box-orient: vertical;
                overflow: hidden;
              }
            }
          }

          &:hover .edit-btn-hover {
            opacity: 1;
          }
          // &:hover > div {
          //   opacity: 0.5;
          // }
        }
      }
    }

    .feature-section {
      padding: 0px;
      padding-top: 6px;
      height: 240px;
      position: relative;
      margin: 0;

      .high-div {
        display: flex;
        height: 100%;

        .feature-image-container {
          position: relative;
          width: 180px;
        }
        .feature-image {
          height: 100%;
          width: 180px;
          background: center no-repeat url('../../../images/camera.svg');
          background-size: 64px;
        }
        .h-item {
          padding-left: 6px;
          position: relative;
          .feature-title {
            background: #1d759e;
            color: white;
            font-family: 'MontserratM';
            font-size: 20px;
            margin-top: 0;
            padding: 10px 15px;
            height: 40px;
            width: 646px;
          }
          .feature-container {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            height: 240px;

            .feature-description {
              padding: 10px 15px;
              margin: 0 16px;
              font-size: 13px;
              word-spacing: 2px;
              line-height: 18px;
              display: flex;
              flex-wrap: wrap;
              font-family: 'MontserratR';

              .list {
                list-style: disc;
                padding: 10px;
                padding-left: 0px;
                width: 50%;
              }
            }

            .break-line {
              background-color: #1d759e;
              height: 1.5px;
              width: 100%;
            }

            .footer-logo-container {
              margin-top: -20px;
              padding: 0 15px 15px 0;
              display: flex;
              justify-content: flex-end;
              .logo-footer {
                width: 200px;
                height: 60px;
                object-fit: contain;
              }
            }
            &:hover .edit-btn-hover {
              opacity: 1;
            }
            // &:hover > div {
            //   opacity: 0.5;
            // }
          }
        }
      }
    }
  }
  // ul.universal-list li {
  //   position: relative;
  // }
  // ul.universal-list li::before {
  //   content: '';
  //   width: 5px;
  //   position: absolute;
  //   height: 5px;
  //   background: #000;
  //   border-radius: 50%;
  //   left: -15px;
  //   top: 7px;
  // }
}
