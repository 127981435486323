.pem-template-2.page-1 {
  width: 1224px;
  height: 792px;
  padding: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;

  .listing-desc-container {
    height: 127px;
  }
}

.pem-template-2.page-2 {
  width: 1224px;
  height: 792px;
  padding: 35px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-sizing: border-box;
  flex-wrap: wrap;
}
