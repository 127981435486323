.vw-social-template-7-wrapper {
  position: relative !important;
  overflow: hidden !important;
  height: 1080px;
  width: 1080px;

  .image1 {
    position: absolute;
    height: 1080px;
    width: 1080px;
    object-fit: cover;
    background: center no-repeat url('../../../images/camera.svg');
    background-size: 64px;
  }

  .solid-ribbon {
    width: 60%;
    background-color: #1c76a0;
    color: white;
    font-weight: 700;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 40px;
    letter-spacing: 2px;
    margin: 0 auto;

    .address {
      font-family: 'MontserratR';
      font-size: 28px;
      text-align: center;

      textarea {
        margin-top: 16px;
        margin-bottom: 8px;
        overflow-x: hidden;
        color: white;
        &:hover {
          color: black;
        }
      }
    }
  }

  .logo-img {
    position: absolute;
    width: auto;
    height: 150px;
    top: 15px;
    right: 15px;
    object-fit: contain;
  }
  p.sold-ribbon span {
    display: block;
    font-size: 30px;
    font-weight: 400;
    margin-top: 5px;
  }
}
