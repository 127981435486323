// Base styles that are applied for all Whistler Social Templates

@font-face {
  font-family: 'Social-Reg';
  src: url(/fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Social-Bold';
  src: url(/fonts/Lato-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Social-Light';
  src: url(/fonts/Lato-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Bitter-Reg';
  src: url(/fonts/Bitter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'AcuminR';
  src: url(/fonts/Acumin-RPro.ttf) format('truetype');
}

@font-face {
  font-family: 'AcuminB';
  src: url(/fonts/Acumin-BdPro.ttf) format('truetype');
}

.whistler-base-social-template {
  width: 1080px;
  height: 1080px;

  // Whistler branded colors
  --whistler-red: #dd281b;
  --whistler-grey: #9d9d9d;
  --whistler-black: #2c2827;

  // Editable image for template background
  .template-background-image {
    position: absolute;
    width: inherit;
    height: inherit;
  }

  // Transparent black - font white
  .overlay-dark {
    background: rgba(0, 0, 0, 0.6);
    color: white;
  }

  // Transparent white - font black
  .overlay-light {
    background: rgba(255, 255, 255, 0.8);

    .headline {
      color: var(--whistler-red);
    }
  }

  textarea {
    resize: none;
    color: #515153;
  }
}
