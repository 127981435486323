.mac-facebook-6-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 1080px;
    height: 660.423px;
  }

  .image2 {
    position: absolute;
    top: 808.372px;
    left: 0;
    width: 350.01px;
    height: 272.628px;
  }

  .image3 {
    position: absolute;
    top: 808.372px;
    left: 364.995px;
    width: 350.01px;
    height: 272.628px;
  }

  .image4 {
    position: absolute;
    top: 808.372px;
    left: 730.49px;
    width: 350.01px;
    height: 272.628px;
  }

  .address {
    position: absolute;
    top: 690px;
    left: 58.552px;
    width: 787px;

    font-size: 40px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .detail {
    position: absolute;
    top: 744.099px;
    left: 58.552px;
    width: 760px;

    font-size: 30px;
    font-family: 'UbuntuR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .logo {
    position: absolute;
    top: 681px;
    left: 890px;
    width: 175.497px;
    height: auto;
  }
}
