.whistler-social-7-wrapper {
  .image1 {
    position: relative;
    width: inherit;
    height: 600px;
  }

  .details-container {
    background-color: white;
    height: 200px;
    display: flex;
    align-items: center;

    .border {
      border-left: 3px solid var(--whistler-red);
      margin: 0 61px;
      height: 50%;
    }

    .content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 50%;
      width: 80%;

      .address {
        font-size: 40px;
        font-family: 'AcuminR';
        color: var(--whistler-red);
      }

      .detail {
        font-size: 30px;
        font-family: 'AcuminR';
        color: var(--whistler-grey);
      }
    }
  }

  .three-photo-container {
    display: flex;
    height: 280px;
    justify-content: space-between;

    .photo {
      position: relative;
      min-width: 33%;
    }
  }
}
