.mac-template-8 {
  height: 612px;
  width: 792px;
  padding: 35px;
  // padding-top: 0px;
  position: relative;
  overflow: hidden;

  .agent-branding.mac {
    margin-top: 8px;
    .agent-photo {
      height: 105px;
    }

    .agent-info {
      .agent-name {
        line-height: 1.5em;
      }
    }
  }
  .mac-horizontal-window-poster {
    // padding-top: 20px;
  }
  .listing-intro h2 input {
    font-size: 22px !important;
  }
}
