// Base styles that are applied for all Whistler Social Templates

@font-face {
  font-family: 'Social-Reg';
  src: url(/fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Social-Bold';
  src: url(/fonts/Lato-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Social-Light';
  src: url(/fonts/Lato-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Bitter-Reg';
  src: url(/fonts/Bitter-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Roboto';
  src: url(/fonts/Roboto-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'AcuminR';
  src: url(/fonts/Acumin-RPro.ttf) format('truetype');
}

@font-face {
  font-family: 'AcuminB';
  src: url(/fonts/Acumin-BdPro.ttf) format('truetype');
}

.whistler-base-brochure-template {
  // Whistler branded colors
  --whistler-red: #dd281b;
  --whistler-grey: #030101;
  --whistler-black: #2c2827;

  // Editable image for template background
  .template-background-image {
    position: absolute;
    width: inherit;
    height: inherit;
  }

  textarea {
    resize: none;
    color: #515153;
  }

  .ui-only-outline {
    // This seems to push pages in generated pdf
    // margin: 30px 0;
  }
}
