.scale-wrapper {
  margin: auto;

  .scaled ~ br {
    display: none;
  }

  .scale-wrapper-sub {
    & > * {
      position: inherit;
      background-color: white;
      width: 100%;
      height: 100%;
      min-width: 100%;
      min-height: 100%;
    }
  }

  .scale-back {
    position: inherit;
  }
}
