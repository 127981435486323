.blank-listings {
  margin-top: 42px;

  .loader-container {
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
