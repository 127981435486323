.facebook-14-wrapper {
  .image1 {
    position: absolute;
    top: 61.001px;
    left: 260px;
    width: 820px;
    height: 465.998px;
  }

  .image2 {
    position: absolute;
    top: 553.001px;
    left: 260px;
    width: 820px;
    height: 465.998px;
  }

  .content-wrapper {
    position: absolute;
    top: 61.001px;
    left: 0;
    width: 232px;
    height: 957.998px;
  }

  .address1 {
    position: absolute;
    top: 800px;
    left: 60px;
    width: 700px;

    font-size: 48px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: right;
    letter-spacing: 0;
    color: #fff;
    background: transparent;

    transform-origin: 0 0;
    transform: rotate(-90deg);
  }

  .address2 {
    position: absolute;
    top: 800px;
    left: 110px;
    width: 700px;

    font-size: 48px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: right;
    letter-spacing: 0;
    color: #fff;
    background: transparent;

    transform-origin: 0 0;
    transform: rotate(-90deg);
  }

  .logo {
    position: absolute;
    top: 840px;
    left: 13px;
    width: 207.497px;
    height: 168.374px;
  }

  &.black {
    .content-wrapper {
      background-color: black;
    }
  }

  &.green {
    .content-wrapper {
      background-color: #112f23;
    }
  }
}
