.boulevard-template-2-wrapper {
  width: 2175px;
  height: 1415px !important;
  display: flex;
  // background-color: white;

  .footer {
    height: 300px;
    bottom: 0;
    position: absolute;
    width: 2175px;
    display: inline-flex;
    justify-content: space-between;
    padding: 100px 85px;
  }

  .side-container {
    width: 465px;
    height: 1415px;
    display: inline-grid;
    background-color: white;
    text-align: center;

    &-content {
      height: 400px;
      display: block;

      .header {
        height: 47px;
        width: 465px;
      }

      .agent-logo {
        margin-top: 5px;
        width: 219px;
        margin: auto;
        // height: 90px;
      }

      .agent-website {
        color: black;
        font-size: 25px;
        text-align: center;
        font-weight: bold;
      }
    }

    .agent-photo {
      margin-top: 75px;
      width: 455px;
      height: 755px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
      // margin-right: 18px;
      background-color: transparent;
      position: relative;
      z-index: 100;

      .empty-placeholder {
        background-color: white;
        background-image: url('../../../images/person.svg');
        background-size: 150px;
      }
    }
  }

  .sub-main {
    width: 1710px;
    .header-image-container {
      position: relative;
      width: 1710px;
      height: 734px;

      .image1 {
        position: relative;
        width: 1710px;
        height: 734px;

        background: center no-repeat url('../../../images/camera.svg');
        background-size: 64px;
      }
    }
    .image2 {
      position: relative;
      width: 745px;
      height: 462px;
      margin: 10px;
      margin-left: 0;
      z-index: 100;
      background: center no-repeat url('../../../images/camera.svg');
      background-size: 64px;
    }

    .description {
      padding: 50px;
      font-size: 26px;
      display: -webkit-box;
      -webkit-line-clamp: 10;
      -webkit-box-orient: vertical;
      overflow: hidden;
      line-height: 1.2em;
      height: 450px;
    }
  }

  .agent-info {
    color: white;
    width: 50%;
    background-color: transparent;
    justify-content: flex-end;

    flex-direction: column;

    .agent-name {
      font-size: 38px;
      font-weight: bold;
    }

    .agent-phone {
      font-size: 29px;
      line-height: 43px;
      text-align: start;
    }

    .agent-title {
      font-size: 29px;
      line-height: 43px;
    }
    .agent-contact {
      font-size: 29px;
      line-height: 43px;
    }
  }

  .inputs-container {
    width: 50%;
    text-align: end;
    .address {
      font-size: 38px;
      line-height: 43px;

      input {
        font-weight: bold;
      }
    }

    .listingDetails {
      font-size: 29px;
      line-height: 43px;
    }

    .listing-price {
      font-size: 29px;
      line-height: 43px;
      font-weight: bold;

      input {
        font-weight: bold;
      }
    }
  }
}
