.whistler-brochure-1-wrapper {
  .page-1,
  .page-2 {
    width: 612px;
    height: 792px;
    background-color: white;
  }

  .hero-image {
    position: relative;
    height: 500px;
    background: center no-repeat url('../../../images/camera.svg');
    background-size: 64px;
  }

  .content-wrapper {
    height: 210px;
    padding: 0 70px;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    position: relative;

    .address {
      font-family: 'AcuminR';
      font-size: 20px;
    }

    .desc-wrapper {
      .description {
        font-family: 'AcuminR';
        font-size: 11px;
        display: -webkit-box;
        -webkit-line-clamp: 10;
        -webkit-box-orient: vertical;
        overflow: hidden;
        line-height: 1.2em;
      }
    }

    &:hover .edit-btn-hover {
      opacity: 1;
    }

    &:hover > div {
      opacity: 0.5;
    }
  }

  .image-container {
    display: flex;
    flex-wrap: wrap;
    height: 408px;
    justify-content: space-between;
    align-content: space-between;

    .listing-image {
      position: relative;
      height: 200px;
      width: 302px;
      background: center no-repeat url('../../../images/camera.svg');
      background-size: 64px;
    }
  }

  .features-container {
    height: 232px;
    margin: 0 20px 0 20px;
    border-bottom: 1px solid black;
    font-family: 'AcuminR';
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    .features-list {
      width: 450px;
      height: 100px;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;

      .feature {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        line-height: 1.5em;
        & > span {
          // width: 115px;
          width: 160px;
          margin-left: 10px;
        }
      }
    }

    &:hover .edit-btn-hover {
      opacity: 1;
    }

    &:hover > div {
      opacity: 0.5;
    }
  }

  .footer {
    height: 82px;
    background-color: black;
    display: flex;

    .logo {
      position: relative;
      width: 250px;
      margin: auto;
      object-fit: contain !important;
    }
  }

  .page-2 {
    .wrc.agent-branding {
      margin: 10px 20px 0;
      .agent-info {
        font-family: 'AcuminR';
      }
    }

    .agent-banner-container {
      position: relative;
      width: 540px;
      height: 110px;
      margin: 20px auto 0;

      &:hover .edit-btn-hover {
        opacity: 1;
      }

      &:hover > div {
        opacity: 0.5;
      }

      .empty-placeholder {
        background: center no-repeat url('../../../images/person.svg');
        background-size: 64px;
        outline: 0;
      }

      .background-image {
        margin: 20px auto 0;
      }
    }
  }
}
