.facebook-1-wrapper {
  font-family: 'OvoR' !important;
  .image1 {
    position: absolute;
    top: 130px;
    left: 134px;
    width: 946px;
    height: 628px;
  }

  .headline {
    position: absolute;
    top: 793.784px;
    left: 144px;
    width: 660px;

    font-size: 70px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;
    color: #112f23;
    background: transparent;
  }

  .address {
    position: absolute;
    top: 895px;
    left: 144px;
    width: 660px;
    height: 26.384px;

    font-size: 34px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: #112f23;
    background: transparent;
  }

  .logo {
    position: absolute;
    top: 800.216px;
    left: 852.402px;
    width: 216.019px;
    height: auto;
  }

  &.black {
    .headline {
      color: black;
    }
    .address {
      color: black;
    }
  }

  &.green {
    .headline {
      color: #112f23;
    }
    .address {
      color: #112f23;
    }
  }
}
