.mac-facebook-11-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 715.317px;
    height: 465.998px;
  }

  .image2 {
    position: absolute;
    top: 0;
    left: 729.391px;
    width: 350.609px;
    height: 226.242px;
  }

  .image3 {
    position: absolute;
    top: 239.758px;
    left: 729.391px;
    width: 350.609px;
    height: 226.242px;
  }

  .image4 {
    position: absolute;
    top: 613px;
    left: 0;
    width: 350.609px;
    height: 226.242px;
  }

  .image5 {
    position: absolute;
    top: 853.758px;
    left: 0;
    width: 350.609px;
    height: 226.242px;
  }

  .image6 {
    position: absolute;
    top: 614.002px;
    left: 364.683px;
    width: 715.317px;
    height: 465.998px;
  }

  .address {
    position: absolute;
    top: 485px;
    left: 58.552px;
    width: 820px;

    font-size: 40px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .sqft-icon {
    position: absolute;
    top: 544px;
    left: 58px;
    font-size: 30px;
    text-align: left;
    letter-spacing: 0;
    color: #3d3d3d;
    background: transparent;
  }

  .sqft-input {
    position: absolute;
    top: 550px;
    left: 115px;
    width: 211.36px;

    font-size: 30px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .bath-icon {
    position: absolute;
    top: 544px;
    left: 335px;

    font-size: 30px;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .bath-input {
    position: absolute;
    top: 550px;
    left: 396px;
    width: 60px;

    font-size: 30px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .bed-icon {
    position: absolute;
    top: 544px;
    left: 465px;

    font-size: 30px;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .bed-input {
    position: absolute;
    top: 550px;
    left: 523px;
    width: 100px;

    font-size: 30px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .logo {
    position: absolute;
    top: 490px;
    left: 920px;
    width: 181.497px;
    height: auto;
  }
}
