.invite-user {
  .loading-alert-popup {
    position: absolute;
    background: #bbb;
    padding: 10px;
    color: white;
    top: -45px;
    right: -26px;
    white-space: initial;
    width: 200px;
    text-align: left;
    font-size: 14px;
    line-height: 18px;
    border-radius: 4px;
    z-index: 10;
  }
}
