.facebook-13-wrapper {
  .image1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 1080px;
    height: 560.423px;
  }

  .image2 {
    position: absolute;
    top: 808.372px;
    left: 0;
    width: 350.01px;
    height: 272.628px;
  }

  .image3 {
    position: absolute;
    top: 808.372px;
    left: 364.995px;
    width: 350.01px;
    height: 272.628px;
  }

  .image4 {
    position: absolute;
    top: 808.372px;
    left: 730.49px;
    width: 350.01px;
    height: 272.628px;
  }

  .content-wrapper {
    display: flex;
    position: absolute;
    top: 571.844px;
    width: 100%;
    height: 220px;
    align-items: center;
    background-color: #112f23;

    &--header {
      flex: 3;
      padding: 5px;
      padding-top: 0px;
      width: 100%;
    }
  }

  .border {
    border-left: 5px solid white;
    height: 175px;
    margin: 20px 20px 20px 0;
  }

  .headline {
    font-size: 65px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 2px;

    background-color: white;
    opacity: 0.8;
    color: white;
    background: transparent;
  }

  .address {
    font-size: 40px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: white;
    background: transparent;
  }

  .detail {
    font-size: 30px;
    font-family: 'OvoR', Verdana, Tahoma;
    text-align: left;
    letter-spacing: 0;
    color: rgb(61, 61, 61);
    background: transparent;
  }

  .logo {
    margin-left: 40px;
    margin-right: 40px;
  }

  &.black {
    .content-wrapper {
      background-color: black;
    }
  }

  &.green {
    .content-wrapper {
      background-color: #112f23;
    }
    .border {
      border-left: 5px solid #83bd41;
      background-color: #83bd41;
    }
  }
}
