.mac-listing-info {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 540px;
  align-items: flex-start;

  .empty-placeholder {
    background-image: url('../../../../images/camera.svg');
    background-size: 64px;
  }

  .broker-branding {
    width: 100%;
    height: 79px;
    display: flex;
    justify-content: space-between;

    .logo {
      top: -10px;
      position: relative;
    }

    .listing-intro {
      font-size: 25px;
      font-family: 'LuxiaR', Verdana, Tahoma;
      text-align: right;
      color: var(--mac-font-color);

      h1,
      h2 {
        font-size: 28px;
        line-height: 1.2em;
        max-width: 420px;
        max-height: 35px;
        overflow: hidden;
      }
      h2 {
        margin-bottom: 10px;
      }
    }

    .listing-intro div {
      line-height: 30px;
    }
  }

  .photo-layout-1 {
    display: flex;
    flex-direction: row;
    height: 225px;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  .photo-layout-1.large {
    margin-bottom: -3px;
  }

  .photo-layout-1 .stacked {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .photo-layout-2 {
    display: flex;
    // justify-content: normal;
    justify-content: space-between;
    flex-direction: row;
    width: 100%;
  }

  .photo-layout-2 #four {
    margin: 0 7.25px;
  }

  .photo-layout-2 #five {
    margin-right: 8.25px;
  }

  .listing-desc-container,
  .agent-banner-container {
    position: relative;

    &:hover .edit-btn-hover {
      opacity: 1;
    }

    &:hover > div {
      opacity: 0.5;
    }
  }
  .agent-banner-container {
    width: 540px;
    height: 110px;

    .empty-placeholder {
      background-image: url('../../../../images/banner.svg');
      background-size: 64px;
    }

    &:hover .empty-placeholder {
      outline: 1px solid #219bd0;
    }

    &:hover .empty-placeholder {
      opacity: 0.5;
    }
  }

  .listing-desc {
    display: flex;
    justify-content: space-between;
    width: 100%;
    line-height: 24px;
    margin-top: 7px;
  }

  .listing-desc .address {
    font-size: 17px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    color: var(--mac-font-color);
    margin-bottom: 4px;
  }

  .listing-desc .price {
    font-size: 12px;
    font-family: 'UbuntuM', Verdana, Tahoma;
    float: right;
  }

  .description {
    font-family: 'UbuntuL', Verdana, Tahoma;
    font-size: 9.5px;
    line-height: 13px;
    color: #414042;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .agent-banner .empty-placeholder {
    background-image: url('../../../../images/banner.svg');
    background-size: 64px;
  }
}
