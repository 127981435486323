.edit-modal-content {
  .menu {
    margin-bottom: 30px;
  }
  .edit-field {
    margin-bottom: 5px;

    .ui .label {
      width: 180px;
      color: white;
      font-family: 'Primary', Verdana, Tahoma;
      font-weight: 500;
      background-color: #3d3d3d;
    }
  }

  .desc-field {
    padding: 10px;
    border-radius: 3px;
    width: 575px;
    height: 233px;
  }

  .agent-photo {
    height: 150px;
    width: 170px;
  }

  .agent-logo {
    height: 150px;
    width: 325px;
  }

  .agent-banner {
    height: 150px;
    width: 525px;
  }

  .agent-photo,
  .agent-logo,
  .agent-banner {
    position: relative;
    outline: 1px solid #3d3d3d;
  }

  .agent-photo .empty-placeholder {
    background-image: url('../images/banner.svg');
    background-size: 64px;
  }

  .agent-logo .empty-placeholder,
  .agent-banner .empty-placeholder {
    background-image: url('../images/banner.svg');
    background-size: 64px;
  }

  .agent-logo-container {
    min-height: 163px;
  }

  .listing-details {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    height: 300px;
    .edit-field {
      margin-right: 30px;
      input {
        width: 150px;
      }
    }
  }

  .maint-fee-incl {
    .input {
      width: 100%;
    }
  }

  .neighbourhood {
    margin-bottom: 16px;
  }
  .neighbourhood-field {
    padding: 10px;
    border-radius: 3px;
    width: 100%;
    height: 200px;
  }

  .change-photo-btn,
  .change-logo-btn {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    opacity: 0;
  }

  .change-banner-btn {
    position: absolute;
    top: 65%;
    left: 45%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    opacity: 0;
  }

  .agent-photo-container .agent-photo:hover ~ .change-photo-btn,
  .agent-photo-container .change-photo-btn:hover,
  .agent-logo-container .agent-logo:hover ~ .change-logo-btn,
  .agent-logo-container .change-logo-btn:hover,
  .agent-banner-container .agent-banner:hover ~ .change-banner-btn,
  .agent-banner-container .change-banner-btn:hover {
    opacity: 1;
  }

  .agent-banner-container {
    .label {
      width: 90%;
    }
  }

  .agent-banner-container > div:nth-child(2) {
    width: 100%;
    img {
      margin: auto;
    }
  }
}

.edit-modal.ui.large.modal {
  width: 1100px;
}
